import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


import line from '../../assets/images/imgggg/background/line-2.webp'

Team2.propTypes = {
    data: PropTypes.array
};

function Team2(props) {

    const { data } = props
    return (
        <section className="team s2" >
            <div className="shape right"></div>
            <img src={line} alt="" className="img-line" />
            <div className="container" data-aos="fade-up" data-aos-duration="2000">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="block-text center">
                            <h6 className="sub-heading"><span>Team Members</span></h6>
                            <h3 className="heading wow" data-splitting>Our Amazing Team
                                <br />
                                Members</h3>
                        </div>

                    </div>

                    <div className='heading-team' style={{ display: 'flex', justifyContent: 'center', padding: '50px'}}><h5>Software & Web Application</h5></div>
                    {
                        data.slice(4, 19).map(idx => (
                            <div key={idx.id} className="col-xl-3 col-md-6">
                                <div className="team-box">
                                    <div className="image">
                                        <Link to="/team">
                                            <img src={idx.img} alt="exouzia" />
                                        </Link>

                                        <ul className="list-social">
                                            <li><Link to="#">
                                                <span className="icon-facebook"></span>
                                            </Link></li>
                                            <li><Link to="#">
                                                <span className="icon-twiter"></span>
                                            </Link></li>
                                            <li><Link to="#">
                                                <span className="icon-plan"></span>
                                            </Link></li>
                                        </ul>
                                    </div>
                                    <div className="content">
                                        <Link to="/team" className="h5 name">{idx.name}</Link>
                                        <p className="postion">
                                            {idx.position}
                                        </p>


                                    </div>
                                </div>
                            </div>
                        ))
                    }


                    {/* <div className='heading-team' style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}><h5>Digital Marketing</h5></div>
                    {
                        data.slice(14, 22).map(idx => (
                            <div key={idx.id} className="col-xl-3 col-md-6">
                                <div className="team-box">
                                    <div className="image">
                                        <Link to="/team">
                                            <img src={idx.img} alt="exouzia" />
                                        </Link>

                                        <ul className="list-social">
                                            <li><Link to="#">
                                                <span className="icon-facebook"></span>
                                            </Link></li>
                                            <li><Link to="#">
                                                <span className="icon-twiter"></span>
                                            </Link></li>
                                            <li><Link to="#">
                                                <span className="icon-plan"></span>
                                            </Link></li>
                                        </ul>
                                    </div>
                                    <div className="content">
                                        <Link to="/team" className="h5 name">{idx.name}</Link>
                                        <p className="postion">
                                            {idx.position}
                                        </p>


                                    </div>
                                </div>
                            </div>
                        ))
                    }



                    <div className='heading-team' style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}><h5>Sales & Marketing</h5></div>
                    {
                        data.slice(22, 25).map(idx => (
                            <div key={idx.id} className="col-xl-3 col-md-6">
                                <div className="team-box">
                                    <div className="image">
                                        <Link to="/team">
                                            <img src={idx.img} alt="exouzia" />
                                        </Link>

                                        <ul className="list-social">
                                            <li><Link to="#">
                                                <span className="icon-facebook"></span>
                                            </Link></li>
                                            <li><Link to="#">
                                                <span className="icon-twiter"></span>
                                            </Link></li>
                                            <li><Link to="#">
                                                <span className="icon-plan"></span>
                                            </Link></li>
                                        </ul>
                                    </div>
                                    <div className="content">
                                        <Link to="/team" className="h5 name">{idx.name}</Link>
                                        <p className="postion">
                                            {idx.position}
                                        </p>


                                    </div>
                                </div>
                            </div>
                        ))
                    } */}


                </div>


            </div>

            <div  className="container" data-aos="fade-up" data-aos-duration="2000">
                <div className="row justify-content-center">
                <div className='heading-team' style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}><h5>Digital Marketing</h5></div>
                    {
                        data.slice(19, 25).map(idx => (
                            <div key={idx.id} className="col-xl-3 col-md-6">
                                <div className="team-box">
                                    <div className="image">
                                        <Link to="/team">
                                            <img src={idx.img} alt="exouzia" />
                                        </Link>

                                        <ul className="list-social">
                                            <li><Link to="#">
                                                <span className="icon-facebook"></span>
                                            </Link></li>
                                            <li><Link to="#">
                                                <span className="icon-twiter"></span>
                                            </Link></li>
                                            <li><Link to="#">
                                                <span className="icon-plan"></span>
                                            </Link></li>
                                        </ul>
                                    </div>
                                    <div className="content">
                                        <Link to="/team" className="h5 name">{idx.name}</Link>
                                        <p className="postion">
                                            {idx.position}
                                        </p>


                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div  className="container" data-aos="fade-up" data-aos-duration="2000">
                <div className="row justify-content-center">

                <div className='heading-team' style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}><h5>Sales & Marketing</h5></div>
                    {
                        data.slice(25, 29).map(idx => (
                            <div key={idx.id} className="col-xl-3 col-md-6">
                                <div className="team-box">
                                    <div className="image">
                                        <Link to="/team">
                                            <img src={idx.img} alt="exouzia" />
                                        </Link>

                                        <ul className="list-social">
                                            <li><Link to="#">
                                                <span className="icon-facebook"></span>
                                            </Link></li>
                                            <li><Link to="#">
                                                <span className="icon-twiter"></span>
                                            </Link></li>
                                            <li><Link to="#">
                                                <span className="icon-plan"></span>
                                            </Link></li>
                                        </ul>
                                    </div>
                                    <div className="content">
                                        <Link to="/team" className="h5 name">{idx.name}</Link>
                                        <p className="postion">
                                            {idx.position}
                                        </p>


                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                </div>

        </section>
    );
}

export default Team2;