import React from 'react'
import firstBanner from "../assets/images/imgggg/portfolio/logo/Pathraadhi/firstBanner.webp"
import secondBanner from "../assets/images/imgggg/portfolio/logo/Pathraadhi/secondBanner.webp"
import thirdBanner from "../assets/images/imgggg/portfolio/logo/Pathraadhi/thirdBanner.webp"
import fourthBanner from "../assets/images/imgggg/portfolio/logo/Pathraadhi/fourthBanner.webp"
import fifthBanner from "../assets/images/imgggg/portfolio/logo/Pathraadhi/fifthBanner.webp"
import sixthBanner from "../assets/images/imgggg/portfolio/logo/Pathraadhi/sixthBanner.webp"
import seventhBanner from "../assets/images/imgggg/portfolio/logo/Pathraadhi/seventhBanner.webp"
import eigthBanner from "../assets/images/imgggg/portfolio/logo/Pathraadhi/eightBanner.webp"
import ninethBanner from "../assets/images/imgggg/portfolio/logo/Pathraadhi/ninethBanner.webp"
import tenthBanner from "../assets/images/imgggg/portfolio/logo/Pathraadhi/tenthBanner.webp"

function Pathraadhi() {
  return (
    <div className='pathraadhi_body'>
        <div className="banner_images" style={{ padding: "0 0 30px 0" }}>
          <div className="inBetween_banner">
            <img src={firstBanner} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0 0 30px 0" }}>
          <div className="inBetween_banner">
            <img src={secondBanner} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0 0 30px 0" }}>
          <div className="inBetween_banner">
            <img src={thirdBanner} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0 0 30px 0" }}>
          <div className="inBetween_banner">
            <img src={fourthBanner} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0 0 30px 0" }}>
          <div className="inBetween_banner">
            <img src={fifthBanner} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0 0 30px 0" }}>
          <div className="inBetween_banner">
            <img src={sixthBanner} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0 0 30px 0" }}>
          <div className="inBetween_banner">
            <img src={seventhBanner} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0 0 30px 0" }}>
          <div className="inBetween_banner">
            <img src={eigthBanner} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0 0 30px 0" }}>
          <div className="inBetween_banner">
            <img src={ninethBanner} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0 0 30px 0" }}>
          <div className="inBetween_banner">
            <img src={tenthBanner} alt="" />
          </div>
        </div>
    </div>
  )
}

export default Pathraadhi