import React from 'react'
import one from '../assets/images/imgggg/portfolio/logo/teabay/one.webp'
import two from '../assets/images/imgggg/portfolio/logo/teabay/two.webp'
import three from '../assets/images/imgggg/portfolio/logo/teabay/three.webp'
import four from '../assets/images/imgggg/portfolio/logo/teabay/four.webp'

function Teabay() {
  return (
    <div>
      <div className='endora'>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={one} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={two} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={three} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={four} alt="" />
          </div>
        </div>  
      </div>
    </div>
  )
}

export default Teabay