import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../assets/images/imgggg/layouts/Designer (7).webp'
import img2 from '../../assets/images/imgggg/icon/icon-01.png'
// import img3 from '../../assets/images/imgggg/layouts/avt-01.png'
import gsap from "gsap";
import CSSRulePlugin from "gsap/CSSRulePlugin";

function Banner(props) {

    useEffect(() => {
        function animateBanner() {
          var rule = CSSRulePlugin.getRule("span:after");
          var tl = gsap.timeline({ defaults: { duration: 1 } });
    
          tl.from(".heading", { y: -50, stagger: 0.6, opacity: 0 })
            .to(rule, { duration: 1.8, cssRule: { scaleY: 0 } }, "-=2.5")
            // .from(".image", { backgroundPosition: '300px 0px' ,opacity:0}, "-=2.2")
            // .from(".price", { backgroundPosition: '300px 0px' ,opacity:0}, "-=2.2")
            // .from(".owner", { backgroundPosition: '300px 0px' ,opacity:0}, "-=2.2")
        }
       animateBanner();
      }, []);

    return (
        <section className="banner">
                <div className="shape right"></div>
                <div className="container big">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="banner__left">
                                <div className="block-text">
                                    <h2 style={{marginBottom:'19px'}} className="heading">Shaping <br />
                                        Tomorrow's <span className="s1 arlo_tm_animation_text_word">Softwares</span> <br />
                                        Today</h2>
                                    <p style={{marginBottom:'45px'}} className="desc" >Exouzia offers a cutting-edge platform for digital asset ownership, providing access to a wide range of software-based services and solutions.</p>

                                    <Link to="/contact" className="action-btn"><span>Get Connected</span></Link>
                                </div>
                                {/* <div className="pay">
                                    <h6>We are Monteno NFT</h6>

                                    <div className="list">
                                        <p>We accept:</p>

                                        <ul>
                                            <li><Link to="#"><span className="icon-logo-01"></span></Link></li>
                                            <li><Link to="#"><span className="icon-logo-02"></span></Link></li>
                                            <li><Link to="#"><span className="icon-logo-03"></span></Link></li>
                                            <li><Link to="#"><span className="icon-logo-04"></span></Link></li>
                                            <li><Link to="#"><span className="icon-logo-05"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span></Link></li>
                                            <li><Link to="#"><span className="icon-logo-06"></span></Link></li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">

                            <div className="banner__right" data-aos="fade-left">
                                <div className="image">
                                    <img src={img1} alt="exouzia" />
                                </div>

                                <div className="price" style={{display:'none'}}>
                                    <div className="icon">
                                        <img src={img2} alt="exouzia" />
                                    </div>
                                    <div className="content">
                                        <p></p>
                                        <h5></h5>
                                    </div>
                                </div>

                                <div className="owner" style={{display:'none'}}>
                                    <div className="image">
                                        {/* <img src={img3} alt="exouzia" /> */}
                                    </div>
                                    <div className="content">
                                        <h5></h5>
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default Banner;