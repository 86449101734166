
import img1 from '../images/svg/icon-1.svg'
import img2 from '../images/svg/icon-2.svg'
import img3 from '../images/svg/icon-3.svg'
import img4 from '../images/svg/icon-4.svg'
import swdev1 from '../images/svg/swdev.png'
import appdev from '../images/svg/appdev.png'
import webdev from '../images/svg/webdev.png'
import seo from '../images/svg/seo.png'
import marketing from '../images/svg/marketing.png'
import branding from '../images/svg/branding.png'




const dataPortfolio = [

    {
        id: 1,
        img: swdev1,
        title: 'Software Development',
        desc: 'Collaborative process of coding, testing, and refining software to meet user requirements and business goals successfully.',
        link:'/software-development'
    },
    {
        id: 2,
        img: webdev,
        title: 'Website Development',
        desc: 'Crafting digital spaces with code, design, and content to engage and serve online audiences effectively.',
        link:'/Website-development'
    },
    {
        id: 3,
        img: appdev,
        title: 'Application Development',
        desc: 'Crafting software solutions tailored to specific platforms to meet user needs and business objectives effectively',
        link:'/Application-development'
    },
    {
        id: 4,
        img: marketing,
        title: 'Digital Marketing ',
        desc: 'Strategically leveraging online platforms to reach, engage, and convert audiences for brand promotion and business growth.',
        link:'/digital-marketing'
    },
    {
        id: 5,
        img: branding,
        title: 'Holistic Company Branding',
        desc: 'Creating a cohesive brand identity, aligning values, visuals, and messaging to resonate with the target audience',
        link:'/company-branding'
    },
    {
        id: 6,
        img: seo,
        title: 'SEO consulting Services',
        desc: 'Guiding businesses to enhance online visibility and traffic through strategic SEO tactics and analysis',
        link:'/seo-consulting'
    },
]

export default dataPortfolio;