import React ,{useEffect} from 'react';
import dataBox from '../assets/fake-data/data-box';
import dataFaqs from '../assets/fake-data/data-faq';
import dataPortfolio from '../assets/fake-data/data-portfolio';
import dataRoadMap from '../assets/fake-data/data-roadmap';
import dataTestimonials from '../assets/fake-data/data-testimonials';
import dataProject from '../assets/fake-data/dataProject';
import dataTeam from '../assets/fake-data/dataTeam';
import About from '../components/about/About';
import Banner from '../components/banner/Banner';
import Create from '../components/create/Create';
import Faqs from '../components/faqs/Faqs';
import Footer from '../components/footer/Footer';
import Portfolio from '../components/portfolio/Portfolio';
import Project2 from '../components/project/Project2';
import Roadmap from '../components/roadmap/Roadmap';
import Speciality from '../components/speciality/Speciality';
import Team from '../components/team/Team';
import Testimonials from '../components/testimonials/Testimonials';

import dataCard from '../assets/fake-data/data-card';
import Banner2 from '../components/banner/Banner2';
import Project from '../components/project/Project';




function Home01(props) {
    useEffect(() => {
        const header = document.querySelector("header");
        header.style.display = 'block'
    
      }, [])
    return (
        <div className="home-1 wrapper">
            
            <Banner />

            <About />

            <Speciality data={dataBox} />

            <Portfolio data={dataPortfolio} />

            {/* <Project2 data={dataProject} />  the first implemented service part */}

            {/* the project list can be implemented here */}
            
            <Project data={dataProject}/>

            {/* <Banner2 data={dataCard} />  second implemented service type */}
           
            <Roadmap  data={dataRoadMap} />

            <Team data={dataTeam} />

            {/* <Testimonials data={dataTestimonials} /> */}

            <Faqs data={dataFaqs} />

            <Create />

            <Footer />
        </div>
        

    );
}

export default Home01;