import React, { useEffect, useRef } from "react";

import { Power1 } from "gsap";
import { delay } from "framer-motion";

function Transition({ timeline }) {
    const trans = useRef(null)

    useEffect(() => {
        console.log("calls the transition useEffect")
        timeline.to(trans.current, {
            duration: 12,
            y: -6000,
            delay:.7,
            ease: Power1.Out,
        })
    })

    return (
        <div>
            <div className="transition-effect" ref={trans}></div>
        </div>
    )
}
export default Transition ;