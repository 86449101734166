
import icon1 from '../images/icon/quote.png'
// import avt1 from '../images/layouts/avt-02.png'


const dataTestimonials = [

    {
        id: 1,
        icon: icon1,
        text: '“ I have been consistently impressed with the professionalism and expertise of Exouzia. From the initial consultation to the final product delivery, their team has been outstanding. Their innovative solutions and attention to detail have significantly improved our business processes. I highly recommend Exouzia to anyone seeking top-notch software development services.“',
        // avt: avt1,
        name: 'Jacob Jones',
        position: 'Chief Product Officer'
    },
    {
        id: 2,
        icon: icon1,
        text: '“ Choosing Exouzia for our software development needs was one of the best decisions we made. They took the time to understand our requirements thoroughly and provided innovative solutions that exceeded our expectations. Their support and responsiveness throughout the process were exceptional. “',
        // avt: avt1,
        name: 'Jacob Jones',
        position: 'Chief Product Officer'
    },
    {
        id: 3,
        icon: icon1,
        text: '“ Our collaboration with Exouzia has been nothing short of exceptional. Their team demonstrated a deep understanding of our requirements and provided innovative solutions that exceeded our expectations. The professionalism and commitment to excellence exhibited by Exouzia are commendable. They delivered on every promise, ensuring our project was completed on time and within budget. We are confident in recommending Exouzia to any organization seeking high-quality software development services. “',
        // avt: avt1,
        name: 'Jacob Jones',
        position: 'Chief Product Officer'
    },

]

export default dataTestimonials;