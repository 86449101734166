
import img1 from '../images/imgggg/demo/gloduate.webp'
import img2 from '../images/imgggg/demo/tecnocraftman.webp'
import img3 from '../images/imgggg/demo/skyrocket.webp'
import img4 from '../images/imgggg/demo/jasba.webp'
import img5 from '../images/imgggg/demo/minhaj.webp'
import img6 from '../images/imgggg/demo/ingleSlider.webp'



const dataProject = [

    {
        id: 1,
        img: img1,
        title: 'GLODUATE CANADA',
        link : 'https://gloduate.com/'

    },
    {
        id: 2,
        img: img2,
        title: 'TECHNOCRATOMAN',
        link : 'https://technocratoman.com/en/'
    },
    {
        id: 3,
        img: img3,
        title: 'SKYROCKETS',
        link : 'https://skyrockets.in/en/'
    },
    {
        id: 4,
        img: img4,
        title: 'JASBINQA',
        link : 'https://jasbinqa.com/en/'
    },
    {
        id: 5,
        img: img5,
        title: 'MINHAJUL FALAH',
        link : 'https://minhajulfalah.com/en/'
    },
    {
        id: 6,
        img: img6,
        title: 'INGLE CANADA',
        link : 'https://ingle.gettravelinsurance.ca/'
    },
    // {
    //     id: 7,
    //     img: img2,
    //     title: 'King Of Pirates'
    // },
    // {
    //     id: 8,
    //     img: img3,
    //     title: 'Pomeranian Doge'
    // },
    // {
    //     id: 9,
    //     img: img4,
    //     title: 'Nintendo Switch'
    // },


    

]

export default dataProject;