import React , {useState} from 'react';

import img1 from '../../assets/images/imgggg/layouts/Designer.webp'
import img2 from '../../assets/images/imgggg/layouts/meta.webp'
import img3 from '../../assets/images/imgggg/layouts/download.webp'
import img4 from '../../assets/images/imgggg/layouts/globe 5.webp'
import img5 from '../../assets/images/imgggg/layouts/robot looking upward.webp'
import Button from '../button/Button';



function About(props) {

    const [dataImg] = useState([
        {
            id: 1,
            img : img1,
            class: 'img1'
        },
        {
            id: 2,
            img : img2,
            class: 'img2'
        },
        {
            id: 3,
            img : img3,
            class: 'img3'
        },
        {
            id: 4,
            img : img4,
            class: 'img4'
        },
        {
            id: 5,
            img : img5,
            class: 'img5'
        },
    ])

    const [dataBlock] = useState(
        {
            subheading: 'About us',
            heading: 'WE ARE DEVELOP THAT BRING YOUR IDEAS ALIVE',
            desc1: 'EXOUZIA is a holistic software development company that provides customers with A to Z Services in IT and ITES sectors.' ,
            desc2 :'We provide top domain expertise and proficiency in diverse technologies such as SAAS, mobile, and cloud. With rigorous QA standards, we offer cutting-edge solutions. Our team of high-profile technocrats ensures efficient business operations for our clients.'

        }
    )
    return (
        <section className="about">
                <div className="shape"></div>
                <div className="container">
                    <div className="row rev">
                        <div className="col-xl-6 col-md-12">
                            <div className="about__right" >
                                <div className="images">
                                    {
                                        dataImg.map(idx => (
                                            <img key={idx.id} className={idx.class} src={idx.img} alt="exouzia" />
                                        ))
                                    }
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="block-text" >
                                <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                <h3 className="heading">{dataBlock.heading}</h3>
                                {/* <h3 className="heading">WE ARE <span className="devline">DEVELOP</span> THAT BRING YOUR IDEAS ALIVE</h3> */}

                                    <p className="mb-17">{dataBlock.desc1}</p>
                                    <p className="mb-26">{dataBlock.desc2}</p>
                                    <Button link='/about' title='More About Us' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default About;