import React from 'react';



function Page404(props) {
    return (
        <>
            <div className='parent_error'>
                <div className='error'>
                    <h1>404<i class="fa-solid fa-ban"></i></h1>
                    <p>You landed into wrong page !</p>
                </div>
            </div>
        </>
    );
}

export default Page404;