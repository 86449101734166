import React, { useState } from "react";
import PageTitle2 from '../components/pagetitle/PageTitle2'
import Footer from '../components/footer/Footer';
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import { Link } from 'react-router-dom';
import img1 from '../assets/images/imgggg/layouts/appdev 1.webp'
import gsap from "gsap";
import Transition from "../components/transitions/transition";
import smartdawa2 from '../assets/images/imgggg/works/smart-dawa.webp'
import izan2 from '../assets/images/imgggg/works/izan-3.webp'
import posbook2 from '../assets/images/imgggg/works/posbook2(1)(1).webp'

function AppDev(props) {
    const [dataBlock] = useState(
        {
            subheading: 'Our Speciality',
            heading: 'Our Featured Projects',

        }
    )

    const [dataImg] = useState([
        {
            id: 1,
            img: img1,
            class: 'img1'
        },
        // {
        //     id: 2,
        //     img: img2,
        //     class: 'img2'
        // },
        // {
        //     id: 3,
        //     img: img3,
        //     class: 'img3'
        // },
        // {
        //     id: 4,
        //     img: img4,
        //     class: 'img4'
        // },
        // {
        //     id: 5,
        //     img: img5,
        //     class: 'img5'
        // },
    ])
    const [products] = useState([
        {
            id: 1,
            heading: "Izan Digital Mahal ",
            subheading: 'The central initiative revolving around the mahals of Kerala',
            link: '',
            img: izan2,
            desc: 'Streamline mahal management with intuitive features, scheduling tools, and analytics for enhanced efficiency and organization.',
            count: 235
        },
        {
            id: 2,
            heading: "Smart Da'wa",
            subheading: "Foremost enterprise within Kerala's enchanting realm",
            link: '',
            img: smartdawa2,
            desc: 'Empowering mahal administrators with smart tools for seamless operations, scheduling, and data-driven decision-making in a user-friendly interface.',
            count: 100
        },
        // {
        //     id: 3,
        //     heading: 'AI Mahal',
        //     subheading: "Foremost enterprise within Kerala's enchanting realm",
        //     link: '',
        //     img: img1,
        //     desc: 'Empowering mahal administrators with smart tools for seamless operations, scheduling, and data-driven decision-making in a user-friendly interface.',
        //     count: 28
        // },
        {
            id: 4,
            heading: 'POSBOOK PRO',
            subheading: 'An advanced point-of-sale application',
            link: '',
            img: posbook2,
            desc: 'Designed to meet the needs of various retail enviornments. offering features such as inventory management,sales tracking and customer relationship management . ',
            count: 350
        }
    ])
    const [counterOn, setCounterOn] = useState(false);
    const [counterOn1, setCounterOn1] = useState(false);
    const brand = gsap.timeline()
    return (
        <div>
            <Transition timeline={brand} />
            <div className="page-visions wrapper">
                <PageTitle2 title='Application Development' />
                <section className="aboutsp">
                    <div className="container">
                        <div className="row">

                            <div className="col-xl-6 col-md-12">
                                <div className="block-text">
                                    <h6 className="sub-heading"><span>Application Development</span></h6>
                                    <h3 className="heading wow" data-splitting> Engineering Tomorrow's Applications</h3>
                                    <p className="mb-17">Since our inception, we've built exclusive mobile
                                        applications for Apple iOS and Android, as well as cross
                                        platform apps for businesses in India and abroad. <p><br />Our
                                            experienced developers will ensure to deliver
                                            extraordinary apps with outstanding user interface and
                                            intuitive user experience to boost the conversion rates
                                            and and increases revenue and outcome.</p><p><br />
                                            We've delivered successful mobile app development
                                            projects for businesses of variety of clients ranging
                                            from startups to large corporations, and industries such
                                            as corporate Manpower, Restuarant, healthcare, e
                                            learning,campus Management, customer services, and
                                            logistics etc.</p>
                                        <p><br />we provide Android app development,
                                            los app development,
                                            Hybrid app development,
                                            React native app development,
                                            Progressive web app development,
                                            Flutter app development</p></p>
                                </div>

                            </div>
                            <div className="col-xl-6 col-md-12">
                                <div className="about__right">
                                    <div className="images">
                                        {
                                            dataImg.map(idx => (
                                                <img key={idx.id} className={idx.class} src={idx.img} alt="exouzia" />
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="nft">
                    {/* <div className="shape right"></div> */}
                    <div className="container">
                        <div className="row" style={{ justifyContent: 'center' }}>
                            <div className="col-12">
                                <div className="block-text center">
                                    <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                    <h3 className="heading " >{dataBlock.heading}</h3>
                                </div>
                            </div>

                            {/* //.....................previous code............................. classname: project sp */}

                            {/* {
                            products.slice(0,3).map(idx => (
                                <div key={idx.id} className="col-xl-4 col-md-6 " style={{ marginBottom: '10px' }}>
                                    <div className="project-boxx">
                                        <div className="image">
                                            <Link to={idx.link}>
                                                <img src={idx.img} alt="exouzia" />
                                            </Link>
                                        </div>
                                      
                                    </div>
                                    <h6 style={{ fontSize: '25px', display: 'flex', justifyContent: 'start', paddingLeft: '25px' }}>{idx.heading}</h6>
                                    <p style={{ fontSize: '16px', display: 'flex', justifyContent: 'start', paddingLeft: '25px' }}>{idx.subheading}</p>
                                    <p style={{ paddingTop: '18px', paddingLeft: '25px', paddingBottom: '20px' }}>{idx.desc}</p>
                                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                    <h6 style={{ fontSize: '25px'}}>{counterOn && <CountUp start={0} end={idx.count} duration={2} delay={0} />}+</h6>
                                    </ScrollTrigger>
                                </div>
                            ))
                        }
                        {
                            products.slice(3,6).map(idx => (
                                <div key={idx.id} className="col-xl-4 col-md-6 " style={{ marginBottom: '10px' }}>
                                    <div className="project-boxx">
                                        <div className="image">
                                            <Link to={idx.link}>
                                                <img src={idx.img} alt="exouzia" />
                                            </Link>
                                        </div>
                                       
                                    </div>
                                    <h6 style={{ fontSize: '25px', display: 'flex', justifyContent: 'start', paddingLeft: '25px' }}>{idx.heading}</h6>
                                    <p style={{ fontSize: '16px', display: 'flex', justifyContent: 'start', paddingLeft: '25px' }}>{idx.subheading}</p>
                                    <p style={{ paddingTop: '18px', paddingLeft: '25px', paddingBottom: '20px' }}>{idx.desc}</p>
                                    <ScrollTrigger onEnter={() => setCounterOn1(true)} onExit={() => setCounterOn1(false)}>
                                        <h6 style={{ fontSize: '25px'}}>{counterOn1 && <CountUp start={0} end={idx.count} duration={2} delay={0} />}+</h6>
                                    </ScrollTrigger>
                                </div>
                            ))
                        } */}

                            {/* //.....................previous code............................. */}
                            {
                                products.map(idx => (
                                    <div key={idx.id} className="col-xl-4 col-md-6">
                                        <div className="nft-item">
                                            <div className="card-media">
                                                <Link to="#"><img src={idx.img} alt="exouzia" /></Link>
                                            </div>
                                            <div className="card-title1">
                                                <Link to="#" className="h5">{idx.heading}</Link>
                                            </div>
                                            <div className="meta-info">
                                                <div>
                                                    <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'start' }}>{idx.subheading}</p>
                                                    <p style={{ paddingTop: '6px', height: '85px' }}>{idx.desc}</p>
                                                </div>
                                            </div>
                                            {/* <div className="card-bottom style-explode">
                                                <div className="price">
                                                    <span className="icon-logo-01"></span>
                                                    <div className='countplus'>
                                                        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                                            <h6 style={{ fontSize: '20px' }}>{counterOn && <CountUp start={0} end={idx.count} duration={2} delay={0} />}+</h6>
                                                        </ScrollTrigger>
                                                        <div className="meta-info">
                                                            <div className="author">
                                                                <div className="info1">
                                                                    <span>Happy customers</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div> */}
                                        </div>
                                    </div>
                                ))
                            }
                            {/* {
                            products.slice(3, 6).map(idx => (
                                <div key={idx.id} className="col-xl-4 col-md-6">
                                    <div className="nft-item">
                                        <div className="card-media">
                                            <Link to="#"><img src={idx.img} alt="exouzia" /></Link>
                                        </div>
                                        <div className="card-title1">
                                            <Link to="#" className="h5">{idx.heading}</Link>
                                        </div>
                                        <div className="meta-info">
                                            <div>
                                                <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'start' }}>{idx.subheading}</p>
                                                <p style={{ paddingTop: '6px', height: '85px' }}>{idx.desc}</p>
                                            </div>
                                        </div>
                                        <div className="card-bottom style-explode">
                                            <div className="price">
                                                <span className="icon-logo-01"></span>
                                                <div className='countplus'>
                                                    <ScrollTrigger onEnter={() => setCounterOn1(true)} onExit={() => setCounterOn1(false)}>
                                                        <h6 style={{ fontSize: '20px' }}>{counterOn1 && <CountUp start={0} end={idx.count} duration={2} delay={0} />}+</h6>
                                                    </ScrollTrigger>
                                                    <div className="meta-info">
                                                        <div className="author">
                                                            <div className="info1">
                                                                <span>Happy customers</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        } */}


                        </div>
                    </div>
                </section>
                <Footer />

            </div>
        </div>
    );
}
export default AppDev;