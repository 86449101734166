import React from 'react'
import one from '../assets/images/imgggg/portfolio/logo/cocolat/c l 4.webp'
import two from '../assets/images/imgggg/portfolio/logo/cocolat/c l1.webp'
import three from '../assets/images/imgggg/portfolio/logo/cocolat/c l3.webp'

function Cocolat() {
  return (
    <div className="cocolat_body">
      <div className="banner_images">
        <div className='inBetween_banner'>
          <img src={one} alt="" />
        </div>
      </div>
      <div className="banner_images">
        <div className='inBetween_banner'>
          <img src={two} alt="" />
        </div>
      </div>
      <div className="banner_images">
        <div className='inBetween_banner'>
          <img src={three} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Cocolat