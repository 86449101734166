import React ,{useEffect} from "react";
import firstBanner from "../assets/images/imgggg/portfolio/logo/Masadir-Img/masadir_screw.webp";
import LogoConcept from "../assets/images/imgggg/portfolio/logo/Masadir-Img/logo_concept.webp";
import Logo_Anatomy from "../assets/images/imgggg/portfolio/logo/Masadir-Img/logo_anatomy.webp";
import Combination_mark_img1 from "../assets/images/imgggg/portfolio/logo/Masadir-Img/combination_mark.webp";
import wordMark from "../assets/images/imgggg/portfolio/logo/Masadir-Img/word_mark.webp";
import FlagMark from "../assets/images/imgggg/portfolio/logo/Masadir-Img/flag_mark.webp";
import symbolMark from "../assets/images/imgggg/portfolio/logo/Masadir-Img/symbol_mark.webp";
import minimal_gradesInt from "../assets/images/imgggg/portfolio/logo/Masadir-Img/minimal_logo.webp";
import lockups1 from "../assets/images/imgggg/portfolio/logo/Masadir-Img/lockups.webp";
import manBanner from "../assets/images/imgggg/portfolio/logo/Masadir-Img/man_hammer.webp";
import symbol_options1 from "../assets/images/imgggg/portfolio/logo/Masadir-Img/sym1.webp";
import symbol_options2 from "../assets/images/imgggg/portfolio/logo/Masadir-Img/sym2.webp";
import symbol_options3 from "../assets/images/imgggg/portfolio/logo/Masadir-Img/sym3.webp";
import symbol_options4 from "../assets/images/imgggg/portfolio/logo/Masadir-Img/sym4.webp";
import final_banner from "../assets/images/imgggg/portfolio/logo/Masadir-Img/thanksfor.webp";
import bmw_banner from "../assets/images/imgggg/portfolio/logo/Masadir-Img/bmw_banner.webp";
import man_touching from "../assets/images/imgggg/portfolio/logo/Masadir-Img/man_touching.webp";
import incorrectUsage from "../assets/images/imgggg/portfolio/logo/Masadir-Img/incorrectUsage.webp";
import redMan from "../assets/images/imgggg/portfolio/logo/Masadir-Img/redMan.webp";
import labours from "../assets/images/imgggg/portfolio/logo/Masadir-Img/labours.webp";
import logoOnappIcon from "../assets/images/imgggg/portfolio/logo/Masadir-Img/logoOnappIcon.webp";
import logoOnSocialMedia from "../assets/images/imgggg/portfolio/logo/Masadir-Img/logoOnSocialMedia.webp";
import cards from "../assets/images/imgggg/portfolio/logo/Masadir-Img/cards.webp";
import girl from "../assets/images/imgggg/portfolio/logo/Masadir-Img/girl.webp";
import masadir from "../assets/images/imgggg/portfolio/logo/Masadir-Img/masadir.webp";
import letter from "../assets/images/imgggg/portfolio/logo/Masadir-Img/letter.webp";
import clr_palette from "../assets/images/imgggg/portfolio/logo/Masadir-Img/clr_palette.webp"

function Masadir() {
      useEffect(() => {
    const header = document.querySelector("header");
    header.style.display = 'none'

  }, [])
  return (
        <div>
          <div className="masadir_body">
            <div className="initialgrades_div">
              <div className="grades_Col col">
                <h1>
                  LOGO <br /> GUIDELINES
                </h1>
                <div>
                  <span className="thick_dash"></span>
                </div>
                <h2>MASADIR TOOLS TRADING COMPANY BRANDBOOK</h2>
                <h2 style={{ paddingTop: "0" }}> EDUCATION SOCIETY BRAND BOOK</h2>
                <p>Created by team from Exouzia.</p>
                <p>V 1.0 No v'24</p>
              </div>
            </div>
    
            <div className="banner_images" style={{ padding: "0 0 30px 0" }}>
              <div className="inBetween_banner">
                <img src={firstBanner} alt="" />
              </div>
            </div>
    
            <div className="row">
              <div style={{ padding: "0" }}>
                <span className="thick_dash"></span>
              </div>
              <h2>LOGO CONCEPT</h2>
              <div className="inner_images" xs={12} lg={8}>
                <img src={LogoConcept} alt="Logo Concept" />
              </div>
            </div>
    
            <div className="row">
              <div style={{ padding: "0" }}>
                <span className="thick_dash"></span>
              </div>
              <h2>LOGO ANATOMY</h2>
              <div className="inner_images" xs={12} lg={8}>
                <img
                  src={Logo_Anatomy}
                  style={{ padding: "50px" }}
                  alt="Logo_Anatomy"
                />
              </div>
            </div>
    
            <div className="combination_mark">
              <div>
                <span className="thick_dash"></span>
              </div>
              <div className="logoExclusion">
                <h2>LOGO EXCLUSION ZONE</h2>
                <div className="pof_logoExclusion">
                  <p>
                    The Exclusion is essentially for your logo to beclearly
                    distinguishable from other graphic elements. Please avoid
                    positioning any elements close than the defined exclusion zone.
                  </p>
                </div>
              </div>
              <h3 className="h3OfcombMark">COMBINATION MARK</h3>
              <div className="inner_roww">
                <div className="col-6">
                  <div className="inner_images" xs={12} lg={8}>
                    <img src={Combination_mark_img1} alt="Logo Concept" />
                  </div>
                </div>
                <div className="inner_col col-6">
                  <p>1/2 of the branddark height</p>
                </div>
              </div>
              <div className="wordMark row ">
                <div className="wordMarkCol col-6">
                  <h3>WORD MARK</h3>
                  <div className="inner_images" xs={12} lg={8}>
                    <img src={wordMark} alt="Logo Concept" />
                  </div>
                </div>
              </div>
              <div className="row p-0">
                <div className="col-6" xs={6} md={6} lg={6}>
                  <h3 className="clsmark">FLAG MARK</h3>
                  <div className="inner_images" xs={12} lg={8}>
                    <img src={FlagMark} alt="Logo Concept" xs={6} md={6} lg={6} />
                  </div>
                </div>
                <div className="col-6 " xs={6} md={6} lg={6}>
                  <h3 className="clsmark">SYMBOL MARK</h3>
                  <div className="inner_images" xs={12} lg={8}>
                    <img
                      id="symbol_mark"
                      xs={6}
                      md={6}
                      lg={6}
                      src={symbolMark}
                      alt="Logo Concept"
                    />
                  </div>
                </div>
              </div>
            </div>
    
            <div className="minimal_logo row">
              <div style={{ padding: "0" }}>
                <span className="thick_dash"></span>
              </div>
              <h2>MINIMAL LOGO SIZE</h2>
              <div className="inner_div">
                <div className="inner_images">
                  <img src={minimal_gradesInt} alt="Logo Concept" />
                </div>
                {/* <div className="inner_images">
                  <img src={minimal_logo} alt="Logo Concept" />
                </div> */}
              </div>
            </div>
    
            <div className="Lockups row">
              <div style={{ padding: "0" }}>
                <span className="thick_dash"></span>
              </div>
              <h2>LOCKUPS</h2>
              <p>
                here are the minimal logo sizes please avoid reducing it further
              </p>
              <div className="inside_lockups">
                <div className="inner_images" xs={12} lg={8}>
                  <img src={lockups1} alt="Logo Concept" />
                </div>
                {/* <div className="inner_images" xs={12} lg={8}>
                  <img src={lockups2} alt="Logo Concept" />
                </div> */}
              </div>
            </div>
    
            <div className="banner_images">
              <div className="inBetween_banner">
                <img src={manBanner} alt="" />
              </div>
            </div>
    
            <div className="symbolOptions row">
              <div style={{ padding: "0" }}>
                <span className="thick_dash"></span>
              </div>
              <h2>SYMBOL OPTIONS</h2>
              <div className="insideSymbol d-flex">
                <div className="inside_text" xs={12} lg={8}>
                  <h4>Colored on Dark</h4>
                  <br />
                  <p>Colored version of the symbol is preferred.</p>
                </div>
                <div className="inside_img" xs={12} lg={8}>
                  <img src={symbol_options1} alt="Logo Concept" />
                </div>
              </div>
              <div className="insideSymbol d-flex">
                <div className="inside_text" xs={12} lg={8}>
                  <h4>Colored on Light</h4>
                  <br />
                  <p>Colored version of the symbol is preferred.</p>
                </div>
                <div className="inside_img" xs={12} lg={8}>
                  <img src={symbol_options2} alt="Logo Concept" />
                </div>
              </div>
              <div className="insideSymbol d-flex">
                <div className="inside_text" xs={12} lg={8}>
                  <h4>White on Dark</h4>
                  <br />
                  <p>
                    White version of the symbol should be used only for one-color
                    printing.
                  </p>
                </div>
                <div className="inside_img" xs={12} lg={8}>
                  <img src={symbol_options3} alt="Logo Concept" />
                </div>
              </div>
              <div className="insideSymbol d-flex">
                <div className="inside_text" xs={12} lg={8}>
                  <h4>Dark on White</h4>
                  <br />
                  <p>
                    Dark version of the symbol should be used only for one-color
                    printing.
                  </p>
                </div>
                <div className="inside_img" xs={12} lg={8}>
                  <img src={symbol_options4} alt="Logo Concept" />
                </div>
              </div>
            </div>
    
            <div className="banner_images" style={{ padding: "0" }}>
              <div className="inBetween_banner">
                <img src={bmw_banner} alt="" />
              </div>
            </div>
    
            <div className="color_palette">
              <div style={{ padding: "0" }}>
                <span className="thick_dash"></span>
              </div>
              <h2>COLOR PALETTE</h2>
              <div className="row">
                <img src={clr_palette} style={{width:"75%"}} alt="" />
              </div>
              {/* <div className="parentBox">
                <div className="box">
                  <div className="mainBox">
                    <h5 className="RGB">RGB - 6,154,211</h5>
                    <h5 className="CMYK">CMYK - 77,24,2,0</h5>
                    <div className="bgbox">
                      <div className="sqBox" style={{ backgroundColor: "#069ad3" }}>
                        <h3>#069ad3</h3>
                      </div>
                    </div>
                    <div className="smallBoxes">
                      <div
                        className="col-3"
                        style={{ backgroundColor: "#0fa6e2" }}
                      ></div>
                      <div
                        className="col-3"
                        style={{ backgroundColor: "#1eb6f1" }}
                      ></div>
                      <div
                        className="col-3"
                        style={{ backgroundColor: "#2bbef8" }}
                      ></div>
                      <div
                        className="col-3"
                        style={{ backgroundColor: "#44caff" }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="mainBox">
                    <h5 className="RGB">RGB - 70,122,120</h5>
                    <h5 className="CMYK">CMYK - 72,8,70,0</h5>
                    <div className="bgbox">
                      <div className="sqBox" style={{ backgroundColor: "#46ac78" }}>
                        <h3>#46ac78</h3>
                      </div>
                    </div>
                    <div className="smallBoxes">
                      <div
                        className="col-3"
                        style={{ backgroundColor: "#45b47b" }}
                      ></div>
                      <div
                        className="col-3"
                        style={{ backgroundColor: "#59d494" }}
                      ></div>
                      <div
                        className="col-3"
                        style={{ backgroundColor: "#68e9a6" }}
                      ></div>
                      <div
                        className="col-3"
                        style={{ backgroundColor: "#7bfcb9" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
    
            <div className="banner_images" style={{ padding: "0" }}>
              <div className="inBetween_banner">
                <img src={man_touching} alt="" />
              </div>
            </div>
    
            <div className="pTypeFace row">
              <div style={{ padding: "0" }}>
                <span className="thick_dash"></span>
              </div>
              <h2>PRIMARY TYPE FACE</h2>
              <h2 className="minion">Minion Variable Cocept</h2>
              <div className="firstRow row" style={{ padding: "0" }}>
                <div className="firstCol col-6">
                  <h1 className="Aa">Aa</h1>
                  <h2 className="proxima">Proxima Nova</h2>
                </div>
                <div className="secondCol col-6">
                  <h5 className="h5">Aabcdefghij</h5>
                  <h4 className="h4">Aabcdefghij</h4>
                  <h3 className="h3">Aabcdefghij</h3>
                  <h2 className="h2">Aabcdefghij</h2>
                </div>
              </div>
              <div className="secondRow row">
                <h5 style={{ color: "gray" }}>About</h5>
                <p className="para">
                  Prxima Nova is an extremely popular type-face designes by Mark
                  Simonson. It is often described as a hybrid of Futura and Akzidenz
                  Grotesk, combining a geometric appearence with modern proportions.
                  which gives designers a varied palette to work with Proxima Nova
                  is a strong and sophisticated font that can help communicate key
                  messages about a brand or a company.
                </p>
              </div>
            </div>
    
            <div className="incorrectUsage row">
              <div style={{ padding: "0" }}>
                <span className="thick_dash"></span>
              </div>
              <h2>INCORRECT USAGE</h2>
              <div className="parasIncorrect">
                <p>
                  In order to preserve the integrity of the logo, please avoid
                  executions which misuse, amend or trivialize the identity.
                </p>
                <p>Here are some examples of what not to do.</p>
              </div>
              <div className="imgIncorrect row">
                <img src={incorrectUsage} alt="" />
              </div>
            </div>
    
            <div className="logoOnComplexBG row">
              <div style={{ padding: "0" }}>
                <span className="thick_dash"></span>
              </div>
              <h2>USE OF LOGO ON COMPLEX BACKGROUND</h2>
              <p className="paraInComplex">
                The image must contain atleast one zone homogeniety. The logo should
                be exactly in this zone.
              </p>
            </div>
    
            <div className="banner_images" style={{ padding: "0" }}>
              <div className="inBetween_banner">
                <img src={redMan} alt="" />
              </div>
            </div>
    
            <div className="logoOnComplexBGtwo row">
              <div style={{ padding: "0" }}>
                <span className="thick_dash"></span>
              </div>
              <h2>USE OF LOGO ON COMPLEX BACKGROUND</h2>
              <div className="parasDiv">
                <p className="paraInComplex">
                  If the photographic background does not have a homogeniety zone,
                  you can use the logo with the background. This will avoid the logo
                  "getting lost" on complex backgrounds, as well as create a good
                  contrast effect.
                </p>
                <p className="paraInComplex">
                  The size of the logo should be atleast 1/28 and should not exceed
                  1/4 of the original image size. The position depends on the
                  homogenous area of the image.
                </p>
              </div>
              <div className="lbImg row">
                <img src={labours} alt="" />
              </div>
            </div>
    
            <div className="appIcon row">
              <div style={{ padding: "0" }}>
                <span className="thick_dash"></span>
              </div>
              <h2>USE OF LOGO ON APP ICON</h2>
              <p className="paraInComplex">
                Grades logo can be used in different colors for use in social media
                and app formats.
              </p>
              <div className="logoImg row">
                <img src={logoOnappIcon} alt="" />
              </div>
            </div>
    
            <div className="appIcon row">
              <div style={{ padding: "0" }}>
                <span className="thick_dash"></span>
              </div>
              <h2>USE OF LOGO ON SOCIAL MEDIA</h2>
              <p className="paraInComplex">
                Use templates and designs tools to create consistent and
                professional-looking social media graphics. This can help to save
                time and ensure that your design consistent across all social media
                platforms
              </p>
              <div className="logoImg row">
                <img src={logoOnSocialMedia} alt="" />
              </div>
            </div>
    
            <div className="stationary">
              <div style={{ padding: "0" }}>
                <span className="thick_dash"></span>
              </div>
              <h2>STATIONARY</h2>
              <p className="statp">
                Brand Stationary can be used in a variety of settings, such as
                business meetings, conferences, and networking events. By having
                consistent and professional-looking satationary, a brand can create
                a lasting Impression on clients, partners and other stakeholders,
                and help to establish itself as a credible and trustworthy
                organization.
              </p>
            </div>
    
            <div className="banner_images" style={{ padding: "0" }}>
              <div className="inBetween_banner">
                <img src={cards} alt="" />
              </div>
            </div>
    
            <div className="banner_images" style={{ padding: "0" }}>
              <div className="inBetween_banner">
                <img src={girl} alt="" />
              </div>
            </div>
    
            <div className="banner_images" style={{ padding: "0" }}>
              <div className="inBetween_banner">
                <img src={letter} alt="" />
              </div>
            </div>
    
            <div className="banner_images" style={{ padding: "0" }}>
              <div className="inBetween_banner">
                <img src={masadir} alt="" />
              </div>
            </div>
    
            <div className="banner_images" style={{ padding: "0" }}>
              <div className="inBetween_banner">
                <img src={final_banner} alt="" />
              </div>
            </div>
          </div>
        </div>
      );
    }
    

export default Masadir;
