// import React from "react";
// import PageTitle2 from '../components/pagetitle/PageTitle2'
// import Footer from '../components/footer/Footer';


// function Branding(props) {
//     return (
//         <div className="wrapper">
//             <PageTitle2 title = 'Wholistic Company Branding' />
//             <Footer/>

//         </div>
//     );
// }
// export default Branding;

import React, { useState } from "react";
import PageTitle2 from '../components/pagetitle/PageTitle2'
import Footer from '../components/footer/Footer';

import img1 from '../assets/images/imgggg/layouts/overworked-businessman-showing-financial-graphs-presentation-using-tablet-brainstorming-company-ideas.webp'
import { Link } from 'react-router-dom';

import gsap from 'gsap';
import Flip from 'gsap/Flip';
import Transition from "../components/transitions/transition";
function Branding(props) {
    const [dataImg] = useState([
        {
            id: 1,
            img: img1,
            class: 'img1'
        },
        // {
        //     id: 2,
        //     img: img2,
        //     class: 'img2'
        // },
        // {
        //     id: 3,
        //     img: img3,
        //     class: 'img3'
        // },
        // {
        //     id: 4,
        //     img: img4,
        //     class: 'img4'
        // },
        // {
        //     id: 5,
        //     img: img5,
        //     class: 'img5'
        // },
    ])
const brand = gsap.timeline()


    return (
        <div>
            <Transition timeline={brand} />
            <div className="page-visions wrapper">
                <PageTitle2 title='Holistic Company Branding' />
                <section className="about">
                    <div className="container">
                        <div className="row">

                            <div className="col-xl-6 col-md-12">
                                <div className="block-text">
                                    <h6 className="sub-heading"><span>Holistic Company Branding</span></h6>
                                    <h3 className="heading wow" data-splitting>Holistic Branding:The Complete Identity Solution</h3>
                                    <p className="mb-17"> At Exouzia , we believe that strong branding is essential for business success. That's why we offer a comprehensive range of services to help you build and maintain a cohesive brand identity.
                                        <p><br /> Our team specializes in creating eye-catching logos that capture the essence of your brand and designing corporate identity packages that reflect your values and personality.</p>
                                        <p><br />We also provide a variety of marketing materials, including catalogs, flyers, brochures, and profiles, to help you promote your products and services effectively. </p>
                                        <p><br />Additionally, we offer professional business collateral such as visiting cards and letterheads to ensure consistency across all your communications. With our expertise and attention to detail, we can help you create a strong and memorable brand presence that sets you apart from the competition .</p> <p><br />Partner with us for maximum impact and long-term success.</p></p>

                                </div>

                                <button className="action-btn"  ><Link to={`/company-branding/works`}>Check out our works</Link></button>
                            </div>
                            <div className="col-xl-6 col-md-12">
                                <div className="about__right">
                                    <div className="images">
                                        {
                                            dataImg.map(idx => (
                                                <img key={idx.id} className={idx.class} src={idx.img} alt="exouzia" />
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <Footer />

            </div>
        </div>
    );
}
export default Branding;