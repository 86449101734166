
import img1 from '../images/icon/icon1.png'
import img2 from '../images/icon/icon2.png'
import img3 from '../images/icon/icon3.png'
import img4 from '../images/icon/icon4.png'


const dataBox = [

    {
        id: 1,
        img: img1,
        title: 'Innovative Solutions',
        desc: 'Providing end-to-end software services'
    },
    {
        id: 2,
        img: img2,
        title: 'Exceptional Quality',
        desc: 'Ensuring top-notch performance and reliability'
    },
    {
        id: 3,
        img: img3,
        title: 'Expert Insights',
        desc: 'Harnessing the expertise of top developers'
    },
    {
        id: 4,
        img: img4,
        title: 'Responsive Support',
        desc: 'Ensuring quick and effective customer assistance'
    },

]

export default dataBox;