
import mansoorali from '../images/imgggg/Team-members/ex m 1-02.webp'
import fasalurahman from '../images/imgggg/Team-members/fasal sir.webp'
import yusufSaqafi from '../images/imgggg/Team-members/yusuf saqafi.webp'
import irfan from '../images/imgggg/Team-members/irfan sir.webp'
import rafid from '../images/imgggg/Team-members/rafid k a.webp'
import shameer from '../images/imgggg/Team-members/shameer.webp'
import shibil from '../images/imgggg/Team-members/shibil.webp'
import nithin from '../images/imgggg/Team-members/NITHIN-01 - Copy.webp'
import shafipp from '../images/imgggg/Team-members/shafi p p.webp'
import shahidm from '../images/imgggg/Team-members/shahid.webp'
import aslam from '../images/imgggg/Team-members/aslam k.webp'
import shafeeda from '../images/imgggg/Team-members/safeeda.webp'
import thangal from '../images/imgggg/Team-members/thangal.webp'
import roshith from '../images/imgggg/Team-members/roshith.webp'
import haritha from '../images/imgggg/Team-members/haritha p.webp'
import ajmal from '../images/imgggg/Team-members/ajmal.webp'
import musthafa from '../images/imgggg/Team-members/musthafa.webp'
import shaheer from '../images/imgggg/Team-members/shaheer.webp'
import jawad from '../images/imgggg/Team-members/jawad.webp'
import shafi_designer from '../images/imgggg/Team-members/shafi designer.webp'
import shameem from '../images/imgggg/Team-members/shameem.webp'
import abdullah from '../images/imgggg/Team-members/abdullah.webp'
import ayoob from '../images/imgggg/Team-members/ayoob.webp'
import afsal from '../images/imgggg/Team-members/afsal.webp'
import ashraf from '../images/imgggg/Team-members/ashraf.webp'  
import ishaq from '../images/imgggg/Team-members/ishaq.webp'  
import usman_saqafi from '../images/imgggg/Team-members/usman saqafi.webp'  
import sayyid_muzammil from '../images/imgggg/Team-members/sayyid muzammil.webp'  
import anzil from '../images/imgggg/Team-members/anzil k.webp'  


const dataTeam = [

    {
        id: 1,
        img: yusufSaqafi,
        name: 'YOUSUF SAQUAFI',
        position: 'Chairman & founder'
    },
    {
        id: 2,
        img: irfan,
        name: 'MUHAMMED IRFAN',
        position: 'CEO & Co-founder'
    },

    {
        id: 3,
        img: mansoorali,
        name: 'DR MANSOOR ALI M ',
        position: 'HR ,Training & Development'
    },

    {
        id: 4,
        img: fasalurahman,
        name: 'FASALU RAHMAN',
        position: 'Managing Director, Co-founder'
    },

    {
        id: 5,
        img: rafid,
        name: 'RAFID K A',
        position: 'PHP Developer'
    },
    {
        id: 6,
        img: shameer,
        name: 'SHAMEER',
        position: 'PHP Developer'
    },
    {
        id: 7,
        img: shibil,
        name: 'SHIBIL K ',
        position: 'PHP Developer'
    },
    {
        id: 8,
        img: shafeeda,
        name: 'SAFEEDA FARZANA ',
        position: 'PHP Developer'
    },

    {
        id: 9,
        img: nithin,
        name: 'NITHIN M R',
        position: 'REACT Developer'
    },

    {
        id: 10,
        img: anzil,
        name: 'ANZIL A K',
        position: 'REACT Developer'
    },

    {
        id: 11,
        img: shafipp,
        name: 'SHAFI P P',
        position: '.NET Developer'
    },

    {
        id: 12,
        img: shahidm,
        name: 'SHAHID M',
        position: '.NET Developer'
    },

    {
        id: 13,
        img: aslam,
        name: 'ASLAM K',
        position: '.NET Developer'
    },

    {
        id: 14,
        img: thangal,
        name: 'SAYYID SHAFIHULLAH P.T',
        position: 'ANGULAR Developer'
    },

    {
        id: 15,
        img: roshith,
        name: 'ROSHITH P',
        position: 'ANGULAR Developer'
    },

    {
        id: 16,
        img: haritha,
        name: 'HARITHA P',
        position: 'ANGULAR Developer'
    },
    {
        id: 17,
        img: ajmal,
        name: 'AJMAL',
        position: 'Performance Test Developer'
    },
    {
        id: 18,
        img: musthafa,
        name: 'MUSTHAFA',
        position: 'Performance Test Developer'
    },
    {
        id: 19,
        img: shaheer,
        name: 'SHAHEER',
        position: 'Quality Assurance Developer'
    },

    // Digital Marketing

    {
        id: 20,
        img: jawad,
        name: 'JAWAD K',
        position: 'Sr.Creative Designer'
    },

    // {
    //     id: 16,
    //     img: img3,
    //     name: 'SADIKH',
    //     position: 'Creative Designer'
    // },

    {
        id: 21,
        img: shafi_designer,
        name: 'SHAFI',
        position: 'Creative Designer'
    },

    {
        id: 22,
        img: shameem,
        name: 'SHAMEEM',
        position: 'Digital Marketing Analyst'
    },

    // {
    //     id: 19,
    //     img: img3,
    //     name: 'AJAY',
    //     position: 'Digital Marketing Analyst'
    // },

    {
        id: 23,
        img: abdullah,
        name: 'ABDULLAH',
        position: 'Content Creator'
    },

    {
        id: 24,
        img: ayoob,
        name: 'AYOOB',
        position: 'Content Creator'
    },

    {
        id: 25,
        img: afsal,
        name: 'AFSAL',
        position: 'Content Creator'
    },

    // Sales and

    {
        id: 26,
        img: ashraf,
        name: 'ASHRAF',
        position: 'Administrator'
    },
    {
        id: 27,
        img: ishaq,
        name: 'ISHAQ',
        position: 'Marketing Excecutive'
    },

    {
        id: 28,
        img: usman_saqafi,
        name: 'USMAN SAQAFI',
        position: 'Software Solutions Consultant'
    },

    {
        id: 29,
        img: sayyid_muzammil,
        name: 'SAYYID MUZAMMIL',
        position: 'Software Solutions Consultant'
    }





]

export default dataTeam;