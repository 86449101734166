
import {React , useEffect} from 'react';
import AOS from 'aos';
import { Route, Routes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion'; // Import AnimatePresence
import routes from './pages';
import Page404 from './pages/404';
import Header from './components/header/Header';

function App() {

    useEffect(() => {
        AOS.init({
          duration : 2000
        }); 
      }, []);

    return (
        // <>

        //     <Header />

        //     <Routes>

        //         {
        //             routes.map((data,idx) => (
        //                 <Route key={idx} path={data.path} element={data.component} exact />
        //             ))
        //         }

        //         <Route path='*' element={<Page404 />} />
        //     </Routes>

    
        // </>
        <>
            <Header />
            <Routes>
                {routes.map((data, idx) => (
                    data.path === '/test4/:paramsId' ? (
                        <Route key={idx} path={data.path} element={
                            <AnimatePresence mode='wait'>
                                <Routes>
                                    <Route element={data.component} />
                                </Routes>
                            </AnimatePresence>
                        } />
                    ) : (
                        <Route key={idx} path={data.path} element={data.component} />
                    )
                ))}
                <Route path='*' element={<Page404 />} />
            </Routes>
        </>
    );
}

export default App;
