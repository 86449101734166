import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom';
import gsap from 'gsap';
import Flip from 'gsap/Flip';
import img1 from '../assets/images/imgggg/background/sm4.webp';
import img2 from '../assets/images/imgggg/background/logo designing.webp';
import img3 from '../assets/images/imgggg/background/landscape brochure.webp';
import img4 from '../assets/images/imgggg/background/catalogue.webp';
import img5 from '../assets/images/imgggg/background/hoarding .webp';
import img6 from '../assets/images/imgggg/background/maazine.webp';
import img7 from '../assets/images/imgggg/background/press and ads.webp';
import img8 from '../assets/images/imgggg/background/web dev.webp';
import { Row, Col } from 'react-bootstrap';


import sm1 from '../assets/images/imgggg/portfolio/social media/sm1.webp'
import sm2 from '../assets/images/imgggg/portfolio/social media/sm2.webp'
import sm3 from '../assets/images/imgggg/portfolio/social media/sm3.webp'
import sm4 from '../assets/images/imgggg/portfolio/social media/sm4.webp'
import sm5 from '../assets/images/imgggg/portfolio/social media/sm5.webp'
import sm6 from '../assets/images/imgggg/portfolio/social media/sm6.webp'
import sm7 from '../assets/images/imgggg/portfolio/social media/sm7.webp'
import sm8 from '../assets/images/imgggg/portfolio/social media/sm8.webp'
import sm9 from '../assets/images/imgggg/portfolio/social media/sm9.webp'
import sm10 from '../assets/images/imgggg/portfolio/social media/sm10.webp'
import sm11 from '../assets/images/imgggg/portfolio/social media/sm11.webp'
import sm12 from '../assets/images/imgggg/portfolio/social media/sm12.webp'
import sm14 from '../assets/images/imgggg/portfolio/social media/sm14.webp'


import brochure1 from '../assets/images/imgggg/portfolio/brochure/3.webp'
import brochure2 from '../assets/images/imgggg/portfolio/brochure/4.webp'
import brochure3 from '../assets/images/imgggg/portfolio/brochure/5.webp'
import brochure4 from '../assets/images/imgggg/portfolio/brochure/6.webp'
import brochure5 from '../assets/images/imgggg/portfolio/brochure/7.webp'
import brochure6 from '../assets/images/imgggg/portfolio/brochure/8.webp'
import brochure7 from '../assets/images/imgggg/portfolio/brochure/Picture.webp'
import brochure8 from '../assets/images/imgggg/portfolio/brochure/Picture2.webp'


import logo1 from '../assets/images/imgggg/portfolio/logo/10.webp'
import logo2 from '../assets/images/imgggg/portfolio/logo/9.webp'
import logo3 from '../assets/images/imgggg/portfolio/logo/8.webp'
import logo4 from '../assets/images/imgggg/portfolio/logo/7.webp'
import logo5 from '../assets/images/imgggg/portfolio/logo/6.webp'
import logo6 from '../assets/images/imgggg/portfolio/logo/5.webp'
import logo7 from '../assets/images/imgggg/portfolio/logo/4.webp'
// import logo8 from '../assets/images/imgggg/portfolio/logo/'
import logo9 from '../assets/images/imgggg/portfolio/logo/2.webp'
import logo10 from '../assets/images/imgggg/portfolio/logo/11.webp'
import logo11 from '../assets/images/imgggg/portfolio/logo/Picture.webp'

import magazine1 from '../assets/images/imgggg/portfolio/magazine/2.webp'
import magazine2 from '../assets/images/imgggg/portfolio/magazine/3.webp'
import magazine3 from '../assets/images/imgggg/portfolio/magazine/4.webp'
import magazine4 from '../assets/images/imgggg/portfolio/magazine/Picture.webp'

import ads1 from '../assets/images/imgggg/portfolio/press-and-ads/1.webp'
import ads2 from '../assets/images/imgggg/portfolio/press-and-ads/2.webp'
import ads3 from '../assets/images/imgggg/portfolio/press-and-ads/3.webp'
import ads4 from '../assets/images/imgggg/portfolio/press-and-ads/4.webp'

import web1 from '../assets/images/imgggg/portfolio/web/1.webp'
import web2 from '../assets/images/imgggg/portfolio/web/2.webp'
import web3 from '../assets/images/imgggg/portfolio/web/3.webp'
import web4 from '../assets/images/imgggg/portfolio/web/4.webp'
import web5 from '../assets/images/imgggg/portfolio/web/5.webp'

import catalogue from '../assets/images/imgggg/portfolio/catalogue/catalogue.png'

import hoarding1 from '../assets/images/imgggg/portfolio/hoarding/2.png'
import hoarding2 from '../assets/images/imgggg/portfolio/hoarding/3.png'
import hoarding3 from '../assets/images/imgggg/portfolio/hoarding/Picture.png'
import { Link } from 'react-router-dom';


import Transition from "../components/transitions/transition";

const data = [
  {
    id: 1,
    sliderImg: img1,
    imgData: [sm1, sm9, sm7, sm2, sm3, sm4, sm5, sm6, sm8, sm14, sm10, sm11, sm12],
    heading: "Social Media",
  },
  {
    id: 2,
    sliderImg: img2,
    imgData: [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo9, logo10, logo11],
    heading: "Logo Branding",
  },
  {
    id: 3,
    sliderImg: img3,
    imgData: [brochure1, brochure2, brochure3, brochure4, brochure5, brochure6, brochure7, brochure8],
    heading: "Landscape Brochure",
  },
  {
    id: 4,
    sliderImg: img4,
    imgData: [catalogue],
    heading: "Catalogue",
  },
  {
    id: 5,
    sliderImg: img5,
    imgData: [hoarding1, hoarding2, hoarding3],
    heading: "Hoarding",
  }, {
    id: 6,
    sliderImg: img6,
    imgData: [magazine1, magazine2, magazine3, magazine4],
    heading: "Magazine and Notice",
  }, {
    id: 7,
    sliderImg: img7,
    imgData: [ads1, ads2, ads3, ads4],
    heading: "Press and Ads",
  }, {
    id: 8,
    sliderImg: img8,
    imgData: [web1, web2, web3, web4, web5],
    heading: "Web",
  },
];

const Slider2 = () => {

  const sliderFunction = () => {
    gsap.registerPlugin(Flip);

    let tl = gsap.timeline({ delay: 0 });

    tl.to(".col", {
      top: 0,
      duration: 3,
      ease: "power4.inOut"
    });

    tl.to(".c-1 .item", {
      top: 0,
      stagger: 0.25,
      duration: 3,
      ease: "power4.inOut"
    }, "-=2");

    tl.to(".c-2 .item", {
      top: 0,
      stagger: -0.25,
      duration: 3,
      ease: "power4.inOut"
    }, "-=4");

    tl.to(".c-3 .item", {
      top: 0,
      stagger: 0.4,
      duration: 3,
      ease: "power4.inOut"
    }, "-=5");
    tl.to(".c-4 .item", {
      top: 0,
      stagger: -0.25,
      duration: 3,
      ease: "power4.inOut"
    }, "-=4");
    tl.to(".c-5 .item", {
      top: 0,
      stagger: 0.25,
      duration: 3,
      ease: "power4.inOut",

    }, "-=4");


    tl.to("#containerId", {
      scale: 5.941,
      stagger: -0.25,
      duration: 3,
      ease: "power4.inOut",
      height: '100vh',
      onComplete: () => preloaderToSlide()
    }, "-=2");
    localStorage.setItem('animation', true)

  }

  var animation = localStorage.getItem("animation")

  useEffect(() => {
    // gsap.registerPlugin(Flip);


    // let tl = gsap.timeline({ delay: 0 });

    // tl.to(".col", {
    //   top: 0,
    //   duration: 3,
    //   ease: "power4.inOut"
    // });

    // tl.to(".c-1 .item", {
    //   top: 0,
    //   stagger: 0.25,
    //   duration: 3,
    //   ease: "power4.inOut"
    // }, "-=2");

    // tl.to(".c-2 .item", {
    //   top: 0,
    //   stagger: -0.25,
    //   duration: 3,
    //   ease: "power4.inOut"
    // }, "-=4");

    // tl.to(".c-3 .item", {
    //   top: 0,
    //   stagger: 0.4,
    //   duration: 3,
    //   ease: "power4.inOut"
    // }, "-=5");
    // tl.to(".c-4 .item", {
    //   top: 0,
    //   stagger: -0.25,
    //   duration: 3,
    //   ease: "power4.inOut"
    // }, "-=4");
    // tl.to(".c-5 .item", {
    //   top: 0,
    //   stagger: 0.25,
    //   duration: 3,
    //   ease: "power4.inOut",

    // }, "-=4");


    // tl.to("#containerId", {
    //   scale: 5.941,
    //   stagger: -0.25,
    //   duration: 3,
    //   ease: "power4.inOut",
    //   height: '100vh',
    //   onComplete: () => preloaderToSlide()
    // }, "-=2");

    // tl.to(".preview img", {
    //   top: 0,
    //   stagger: 0.075,
    //   duration: 1,
    //   ease: "power3.out"
    // }, "-=1.5");


    if (animation) {
      // console.log("enters the if ")
      // console.log(animation, "animation value")
      preloaderToSlide()
    } else {
      sliderFunction()
    }




  });

  const preloaderToSlide = () => {
    localStorage.setItem('animation', true)
    const preloaderContainer = document.querySelector('#containerId')
    preloaderContainer.style.display = 'none'
    const sliderDiv = document.querySelector('.slider')
    sliderDiv.style.display = ''
    gsap.to(preloaderContainer, {
      onComplete: previewFirstLoader
    });

  }

  const previewFirstLoader = () => {
    let tl = gsap.timeline({ delay: 0 });
    const previewGrid = document.querySelector('.image-grid')
    const hiddenGridimages = document.querySelectorAll('.image-grid-hide img')
    const state = Flip.getState(previewGrid);
    hiddenGridimages.forEach(image => {
      previewGrid.appendChild(image);
    });
    return Flip.from(state, {
      duration: 3,
      ease: 'expo.inOut',
      stagger: 0.15,
      absolute: true
    });
  }
  const [active, setActive] = useState(0);
  const [autoplay, setAutoplay] = useState(0);
  const max = data.length;

  const intervalBetweenSlides = () => autoplay && setActive(active === max - 1 ? 0 : active + 1);

  useEffect(() => {
    const interval = setInterval(() => intervalBetweenSlides(), 3000);
    return () => clearInterval(interval);
  });

  const toggleAutoPlay = () => setAutoplay(!autoplay);

  const nextOne = () => active < max - 1 && setActive(active + 1);

  const prevOne = () => active > 0 && setActive(active - 1);

  const isActive = value => active === value && 'active';

  const setSliderStyles = () => {
    const transition = active * -100;

    return {
      width: (data.length * 100) + 'vw',
      transform: 'translateX(' + transition + 'vw)'
    }
  }

  const renderSlides = () => data.map((item, index) => (
    <img
      className='camilimg'
      key={index}
      src={item.sliderImg}
    />
  ));

  const renderDots = () => data.map((slide, index) => (
    <li
      className={isActive(index) + ' dots'}
      key={index}>
      <button onClick={() => setActive(index)}>
        <span>&#9679;</span>
      </button>
    </li>
  ));

  const renderPlayStop = () => autoplay
    ? (
      <svg fill='#FFFFFF' height='24' viewBox='0 0 24 24' width='24'>
        <path d='M0 0h24v24H0z' fill='none' />
        <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 14H9V8h2v8zm4 0h-2V8h2v8z' />
      </svg>
    )
    : (
      <svg fill='#FFFFFF' height='24' viewBox='0 0 24 24' width='24'>
        <path d='M0 0h24v24H0z' fill='none' />
        <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14.5v-9l6 4.5-6 4.5z' />
      </svg>
    );

  const renderArrows = () => (
    <>
      <button
        type='button'
        className='arrows prev'
        onClick={() => prevOne()}>
        <svg fill='#FFFFFF' width='50' height='50' viewBox='0 0 24 24'>
          <path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' />
          <path d='M0 0h24v24H0z' fill='none' />
        </svg>
      </button>
      <button
        type='button'
        className='arrows next'
        onClick={() => nextOne()}>
        <svg fill='#FFFFFF' height='50' viewBox='0 0 24 24' width='50'>
          <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' />
          <path d='M0 0h24v24H0z' fill='none' />
        </svg>
      </button>
    </>
  );


  useEffect(() => {
    const header = document.querySelector("header");
    header.style.display = 'none'

  }, [])

  const renderPreview = () => data.map((item, index) => (
    <img
      className='camilimg'
      key={index}
      src={item.sliderImg}
      onClick={() => setActive(index)}
    />
  ));

  let navigate = useNavigate();
  const toHome = () => {
    navigate('/');
  }
  const toContact = () => {
    navigate('/contact')
  }

  const renderHeading = () => {

    if (data && data[active] && data[active].heading) {

      return (
        <div>
          <a href='/company-branding'><div style={{ position: 'absolute', left: 0, top: 0, marginLeft: '25px', marginTop: '25px', cursor: 'pointer', zIndex: '1' }}> <h6 >Back</h6></div></a>
          <div className="heading-container">
            <div className='div-heading'>
              <p className='home' onClick={()=>toHome()}>Home</p>
              <p className='contact' onClick={()=>toContact()}>Contact</p>
            </div>
          </div>
          <div className="slider-heading" >
            <h4>
              <Link to={`/company-branding/works/${data[active].id}`}><span className='heading1' style={{ fontSize: '50px', fontWeight: 600 }}>{data[active].heading}</span></Link>
            </h4>
            <p style={{ fontSize: '20px', fontWeight: 500 }}>click the heading</p>
          </div>
        </div>

      );
    }
    return null;
  };

  const preloaderKeyValue = localStorage.getItem('animation')
  console.log(preloaderKeyValue)
  const brand = gsap.timeline()






  return (
    <div>
      {animation && <Transition timeline={brand} />}
      <div className='camil'>
        <div id='containerId' className="container" >
          <div className="col c-1" >
            {data[0].imgData.slice(0, 5).map((img, index) => (
              <div className="item"><img className='camilimg' key={index} src={img} /></div>
            ))}
          </div>
          <div className="col c-2" >
            {data[1].imgData.slice(0, 5).map((img, index) => (
              <div className="item"><img className='camilimg' key={index} src={img} /></div>
            ))}
          </div>
          <div className="col c-3" >
            {data[2].imgData.slice(0, 2).map((img, index) => (
              <div className="item"><img className='camilimg' key={index} src={img} /></div>
            ))}
            <div className="item" id='preloaderSlide'><img src={data[0].sliderImg} className="camilimg" /></div>

            {data[2].imgData.slice(3, 5).map((img, index) => (
              <div className="item"><img className='camilimg' key={index} src={img} /></div>
            ))}
          </div>
          <div className="col c-4" >
            {data[7].imgData.slice(0, 5).map((img, index) => (
              <div className="item"><img className='camilimg' key={index} src={img} /></div>
            ))}
          </div>
          <div className="col c-5" >
            {data[1].imgData.slice(5, 11).map((img, index) => (
              <div className="item"><img className='camilimg' key={index} src={img} /></div>
            ))}
          </div>
        </div>
        <section className='slider' style={{ display: 'none', overflow: 'hidden', height: '100vh' }} >

          <div className='wrapper'
            style={setSliderStyles()}

          >
            {renderSlides()}
          </div>

          {renderArrows()}

          <div className="grid grid-col-8 image-grid absolute bottom-0 w-full gap-4 h-60" style={{ zIndex: 1 }}>

          </div>
          {renderHeading()}
          <ul className='dots-container'>
            {renderDots()}
          </ul>
          <div className="grid grid-col-8 image-grid-hide absolute bottom-0 w-full gap-4 h-60" style={{ zIndex: 1 }}>
            {renderPreview()}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Slider2

