import React from 'react'
import one from '../assets/images/imgggg/portfolio/logo/endora/ENDORA B 6.3_pages-to-jpg-0001.webp'
import title from '../assets/images/imgggg/portfolio/logo/endora/title.webp'
import two from '../assets/images/imgggg/portfolio/logo/endora/ENDORA B 6.3_pages-to-jpg-0002.webp'
import three from '../assets/images/imgggg/portfolio/logo/endora/ENDORA B 6.3_pages-to-jpg-0003.webp'
import four from '../assets/images/imgggg/portfolio/logo/endora/ENDORA B 6.3_pages-to-jpg-0004.webp'
import five from '../assets/images/imgggg/portfolio/logo/endora/ENDORA B 6.3_pages-to-jpg-0005.webp'
import six from '../assets/images/imgggg/portfolio/logo/endora/ENDORA B 6.3_pages-to-jpg-0006.webp'
import seven from '../assets/images/imgggg/portfolio/logo/endora/ENDORA B 6.3_pages-to-jpg-0007.webp'
import eight from '../assets/images/imgggg/portfolio/logo/endora/ENDORA B 6.3_pages-to-jpg-0008.webp'

function Endora() {
    return (
        <div className='endora'>
            <div className="initial_page">
                <div className='heading row'>
                    <span><h2>Innovative Design,</h2></span>
                    <span><h2>Effortless Cooking</h2></span>
                </div>
                <div className='img_title row'>
                    <img src={title} alt="" />
                </div>
                <div className='webtitle row'>
                    <span><h5>endoraindia.com</h5></span>
                </div>
            </div>
            {/* <div className="banner_images" style={{ padding: "0" }}>
                <div className='inBetween_banner'>
                    <img src={one} alt="" />
                </div>
            </div> */}
            <div className="banner_images" style={{ padding: "0" }}>
                <div className='inBetween_banner'>
                    <img src={two} alt="" />
                </div>
            </div>
            <div className="banner_images" style={{ padding: "0" }}>
                <div className='inBetween_banner'>
                    <img src={three} alt="" />
                </div>
            </div>
            <div className="banner_images" style={{ padding: "0" }}>
                <div className='inBetween_banner'>
                    <img src={four} alt="" />
                </div>
            </div>
            <div className="banner_images" style={{ padding: "0" }}>
                <div className='inBetween_banner'>
                    <img src={five} alt="" />
                </div>
            </div>
            <div className="banner_images" style={{ padding: "0" }}>
                <div className='inBetween_banner'>
                    <img src={six} alt="" />
                </div>
            </div>
            <div className="banner_images" style={{ padding: "0" }}>
                <div className='inBetween_banner'>
                    <img src={seven} alt="" />
                </div>
            </div>
            <div className="banner_images" style={{ padding: "0" }}>
                <div className='inBetween_banner'>
                    <img src={eight} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Endora