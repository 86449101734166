import React , {useState} from 'react';

import Button from '../button/Button';
import Partner from '../partner/Partner';

import img from '../../assets/images/imgggg/layouts/12 Best Business Coaches to Follow in 2024.webp'


function About2(props) {

    const [dataBlock] = useState(
        {
            subheading: 'About us',
            heading: 'WE ARE DEVELOP THAT BRING YOUR IDEAS ALIVE',

            desc1: 'EXOUZIA is a holistic software development company that provides customers with A to Z Services in IT and ITES sectors.',
            desc2: 'We provide top domain expertise and proficiency in diverse technologies such as SAAS, mobile, and cloud. With rigorous QA standards, we offer cutting-edge solutions. Our team of high-profile technocrats ensures efficient business operations for our clients.',
           
        }
    )
    return (
        <section className="about s2">
                    <div className="shape"></div>
                    <div className="shape right"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="block-text center pd-0">
                                    <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                    <h3 className="heading pd">{dataBlock.heading}</h3>
                                        
                                </div>

                                <div className="about__main center" data-aos="fade-up" data-aos-duration="3000">
                                    <img src={img} alt="exouzia" />
                                    <p className="mb-17">{dataBlock.desc1}</p>
                                    <p className="mb-30">{dataBlock.desc2}</p>
                                    {/* <Button title='More About Us' link='/about' /> */}
                                </div>

                                <Partner />
                            </div>
                        </div>
                    </div>
                </section>
    );
}

export default About2;