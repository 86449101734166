import React from 'react'
import one from '../assets/images/imgggg/portfolio/logo/dispo/d l 1 [1].jpg'
import two from '../assets/images/imgggg/portfolio/logo/dispo/d l.jpg'

function Dispo() {
  return (
    <div className="cocolat_body">
      <div className="banner_images">
        <div className='inBetween_banner'>
          <img src={one} alt="" />
        </div>
      </div>
      <div className="banner_images">
        <div className='inBetween_banner'>
          <img src={two} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Dispo