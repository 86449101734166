


const dataFaqs = [
    {
        id: 1,
        title: 'What types of software development services do you offer?',
        text : '    ~ We offer custom software development, mobile app development, web development, and software maintenance and support.',
        show: ''
    
    },
    {
        id: 2,
        title: 'What technologies do you specialize in?',
        text : 'Our team is proficient in a variety of technologies including Php, React, Angular, Ios,Android, Flutter, Python, .NET, JavaScript, and more.',
        show: ''
    },  
    {
        id: 3,
        title: 'Do you provide post-launch support and maintenance?',
        text : 'Our services include SEO, PPC, social media marketing, content marketing, email marketing, and online reputation management.',
        show: ''
    
    },
    {
        id: 4,
        title: 'What digital marketing services do you offer?',
        text : 'We specialize in digital marketing services including SEO, PPC advertising, and social media strategy. Our team is dedicated to optimizing your online presence, engaging your audience, and achieving measurable results for your business.',
        show: ''
    
    },
    {
        id: 5,
        title: 'How do you measure the success of a digital marketing campaign?',
        text : 'We use various metrics such as website traffic, conversion rates, engagement rates, and ROI to measure campaign success.',
        show: ''
    
    },
    {
        id: 6,
        title: 'What is your approach to SEO?',
        text : 'Our SEO strategy involves keyword research, on-page optimization, link building, and regular performance tracking and adjustments.',
        show: ''
    
    },
    {
        id: 7,
        title: 'What magazine design services do you offer?',
        text : 'We offer full-service magazine design including layout design, cover design, article formatting, and visual content creation.',
        show: ''
    
    },
    {
        id: 8,
        title: 'Can you help with both print and digital magazine designs?',
        text : 'Yes, we specialize in designing for both print and digital formats, ensuring your magazine looks great in any medium.',
        show: ''
    
    },


   

   
]

export default dataFaqs;