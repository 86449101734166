import React, { useEffect } from 'react'

import initialBanner from '../assets/images/imgggg/portfolio/logo/ai-webp/Initial Banner.webp'
import firstBanner from '../assets/images/imgggg/portfolio/logo/ai-webp/Picture1.webp'
import LogoConcept from '../assets/images/imgggg/portfolio/logo/ai-webp/Ai + logo.webp'
import Logo_Anatomy from '../assets/images/imgggg/portfolio/logo/ai-webp/Logo_Anatomy.webp'
import Combination_mark_img1 from '../assets/images/imgggg/portfolio/logo/ai-webp/Combination_mark_img1.webp'
import Combination_mark_img2 from '../assets/images/imgggg/portfolio/logo/ai-webp/Combination_mark_img2.webp'
import FlagMark from '../assets/images/imgggg/portfolio/logo/ai-webp/FlagMark.webp'
import symbolMark from '../assets/images/imgggg/portfolio/logo/ai-webp/symbolMark.webp'
import AimahalBaner from '../assets/images/imgggg/portfolio/logo/ai-webp/AiMahalBanner.webp'
import incorrect1 from '../assets/images/imgggg/portfolio/logo/ai-webp/Incorrect_logo1.webp'
import incorrect2 from '../assets/images/imgggg/portfolio/logo/ai-webp/Incorrect_logo2.webp'
import prayingBanner from '../assets/images/imgggg/portfolio/logo/ai-webp/prayingBanner1.webp'
import quranPic from '../assets/images/imgggg/portfolio/logo/ai-webp/quranPic1.webp'
import PrayerTiming from '../assets/images/imgggg/portfolio/logo/ai-webp/PrayerTiming.webp'
import getToMore from '../assets/images/imgggg/portfolio/logo/ai-webp/gettomore.webp'
import visitingCard from '../assets/images/imgggg/portfolio/logo/ai-webp/visitingCard.webp'
import socialSS from '../assets/images/imgggg/portfolio/logo/ai-webp/social media ss.webp'
import logoOnAppIcon from '../assets/images/imgggg/portfolio/logo/ai-webp/logoOnAppIcon.webp'
import letter from '../assets/images/imgggg/portfolio/logo/ai-webp/letter-removebg-preview.webp'
import tagMahal from '../assets/images/imgggg/portfolio/logo/ai-webp/TagPicture.webp'
import cupPic from '../assets/images/imgggg/portfolio/logo/ai-webp/mugPictures.webp'
import flag from '../assets/images/imgggg/portfolio/logo/ai-webp/flag.webp'
import cards from '../assets/images/imgggg/portfolio/logo/ai-webp/cards-removebg-preview.webp'
import certificate from '../assets/images/imgggg/portfolio/logo/ai-webp/digitalCertificate.webp'
import cardswithCode from '../assets/images/imgggg/portfolio/logo/ai-webp/cardWithBarcode-removebg-preview.webp'
import mosque from '../assets/images/imgggg/portfolio/logo/ai-webp/download.webp'
import bgofStationary from '../assets/images/imgggg/portfolio/logo/ai-webp/second_bg2.webp'
import mug from '../assets/images/imgggg/portfolio/logo/ai-webp/cap_bgremoved.webp'
import tshirt from '../assets/images/imgggg/portfolio/logo/ai-webp/tshirt_bgremoved.webp'
import counter1 from '../assets/images/imgggg/portfolio/logo/ai-webp/download (1).webp'
import bg3 from '../assets/images/imgggg/portfolio/logo/ai-webp/bg3.webp'
import thanks from '../assets/images/imgggg/portfolio/logo/ai-webp/thanksFor.webp'
import { useNavigate } from 'react-router-dom';
import { Row,Col } from 'react-bootstrap'


function AiMahal() {
    useEffect(() => {
        const header = document.querySelector("header");
        header.style.display = 'none'
    }, [])

    let navigate = useNavigate()
    const backClick =()=>{
        navigate('/company-branding/works/2')
    }

    return (
        <div className='mahal_body'>
             <Row style={{width:'100vw'}}>
                <Col><p style={{position:'fixed',top:'25px',left:'25px',zIndex:'1000000',color:'#0c0c45',fontSize:'15px',fontWeight:600,cursor:'pointer'}} onClick={()=>backClick()}>BACK</p></Col>
                {/* <Col>
                <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                <p style={{position:'fixed',top:'25px',zIndex:'1000000',color:'#000'}}>Home</p>
                <p style={{position:'fixed',top:'25px',zIndex:'1000000',color:'#000'}}>Contact</p>
                </div>
                </Col> */}
                
            </Row>
            <div className="initial_div">
                <div className="col">
                    <h1>LOGO <br /> GUIDELINES</h1>
                    <div>
                        <span className='thick_dash'></span>
                    </div>
                    <h2>AiMAHAL.COM THE HOLISTIC MAHAL REFORMS</h2>
                    <h2 style={{ paddingTop: "0" }}>BRAND BOOK</h2>
                    <p>Created by team from <span><h5 style={{ color: "#397491", letterSpacing: "1px" }}>KERALA MUSLIM JAMAATH</h5></span></p>
                    <p>V 1.0 No v'23</p>
                </div>
            </div>

            <div className="banner_images" style={{ padding: "0 0 30px 0" }}>
                <div className='inBetween_banner'>
                    <img src={firstBanner} alt="" />
                </div>
            </div>
            <div className="row" >
                <div style={{ padding: "0" }}>
                    <span className='thick_dash'></span>
                </div>
                <h2>LOGO CONCEPT</h2>
                <div className="inner_images" xs={12} lg={8}>
                    <img src={LogoConcept} alt="Logo Concept" />
                </div>
            </div>
            <div className="row" >
                <div style={{ padding: "0" }}>
                    <span className='thick_dash'></span>
                </div>
                <h2>LOGO ANATOMY</h2>
                <div className="inner_images" xs={12} lg={8}>
                    <img src={Logo_Anatomy} style={{ padding: "50px" }} alt="Logo_Anatomy" />
                </div>
            </div>
            <div className="combination_mark" >
                <div>
                    <span className='thick_dash'></span>
                </div>
                <div className='logoExclusion' >
                    <h2>LOGO EXCLUSION ZONE</h2>
                    <div className='pof_logoExclusion'>
                        <p >The Exclusion is essentially for your logo to beclearly distinguishable from other graphic elements. Please avoid positioning any elements close than the defined exclusion zone.</p>
                    </div>
                </div>
                <div style={{ height: "100px" }}></div>
                <h3 className='pb-5'>COMBINATION MARK</h3>
                <div className="inner_roww">
                    <div className="col-6">
                        <div className="inner_images" xs={12} lg={8}>
                            <img src={Combination_mark_img1} alt="Logo Concept" />
                        </div>
                    </div>
                    <div className="inner_col col-6 pt-4">
                        <p >1/2 of the branddark height</p>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-6">
                        <div className="inner_images" xs={12} lg={8}>
                            <img src={Combination_mark_img2} alt="Logo Concept" />
                        </div>
                    </div>
                    <div className="col-6">

                    </div>
                </div>
                <div className="row p-0">
                    <div className="col-6" xs={6} md={6} lg={6}>
                        <h3 className='clsmark'>FLAG MARK</h3>
                        <div className="inner_images" xs={12} lg={8}>
                            <img src={FlagMark} alt="Logo Concept" xs={6} md={6} lg={6} />
                        </div>
                    </div>
                    <div className="col-6 " xs={6} md={6} lg={6}>
                        <h3 className='clsmark'>SYMBOL MARK</h3>
                        <div className="inner_images" xs={12} lg={8}>
                            <img id='symbol_mark' xs={6} md={6} lg={6} src={symbolMark} alt="Logo Concept" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="banner_images">
                <div className='inBetween_banner'>
                    <img src={AimahalBaner} alt="" />
                </div>
            </div>
            <div className="row" >
                <div className="thick_dash"></div>
                <h2>MAIN COLOR PALETTE</h2>
                <div className='palette_color'></div>
                <div className='inside_palette'>
                    <div className="d-flex justify-content-between pb-5">
                        <div className="hex_values">
                            Hex :   #52a8cf <br />
                            RGB :   82, 168, 207 <br />
                            CMYK :  65, 18, 9, 0
                        </div>
                        <div className="hex_values">
                            Hex :   #397491 <br />
                            RGB :   57, 156, 145 <br />
                            CMYK :  81, 46, 35, 5
                        </div>
                    </div>
                    <h2 className='py-4'>COLOR PALETTE ( ALL USE )</h2>
                    <div className='d-flex'>
                        <div className='all_use_palettes' style={{ backgroundColor: "#397491" }}></div>
                        <span>Hex : #397491 </span>
                    </div>
                    <div className='d-flex'>
                        <div className='all_use_palettes' style={{ backgroundColor: "#52a8cf" }}></div>
                        <span>Hex : #52a8cf </span>
                    </div>
                    <div className='d-flex'>
                        <div className='all_use_palettes' style={{ backgroundColor: "#f2f2f2" }}></div>
                        <span>Hex : #f2f2f2 </span>
                    </div>
                    <div className='d-flex'>
                        <div className='all_use_palettes' style={{ backgroundColor: "#7a7e8d" }}></div>
                        <span>Hex : #7a7e8d </span>
                    </div>
                    <div className='d-flex'>
                        <div className='all_use_palettes' style={{ backgroundColor: "#868b99" }}></div>
                        <span>Hex : #868b99 </span>
                    </div>
                    <div className='d-flex'>
                        <div className='all_use_palettes' style={{ backgroundColor: "#f8f4f0" }}></div>
                        <span>Hex : #f8f4f0 </span>
                    </div>
                    <div className='d-flex'>
                        <div className='all_use_palettes' style={{ backgroundColor: "#fce9d0" }}></div>
                        <span>Hex : #fce9d0 </span>
                    </div>
                    <div className='d-flex'>
                        <div className='all_use_palettes' style={{ backgroundColor: "#261206" }}></div>
                        <span>Hex : #261206 </span>
                    </div>
                </div>
            </div>
            <div className='parrent_incorrect d-flex'>
                <div className='incorrect_usage'>
                </div>
                <div className="inside_parentIncorrect" style={{ paddingTop: "35px" }}>
                    <div className='incorrect_child'>
                        <div className='parent_thickdash' style={{ padding: "0" }}>
                            <span className='thick_dash'></span>
                        </div>
                        <div className='parent_incorrectUsage'>
                            <h2>INCORRECT USAGE</h2>
                            <p>In order to preserve the integrity of the logo please avoid executions which misuse, amend, or trivialize the identity.<br />Here are some examples of what not to do.</p>
                        </div>
                    </div>
                    <div className="row py-5">
                        <div className="incorret col-6">
                            <img src={incorrect1} alt="" />
                        </div>
                        <div className="incorret col-6">
                            <img src={incorrect2} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row py-5">
                <div style={{ padding: "0" }}>
                    <span className='thick_dash'></span>
                </div>
                <h2 >USE OF LOGO ON COMPLEX BACKGROUNDS</h2>
                <div style={{ width: "400px", textAlign: "justify", padding: "0" }}>
                    <p >In order to preserve the integrity of the logo please avoid executions which misuse, amend, or trivialize the identity.<br />Here are some examples of what not to do.</p>
                </div>
            </div>
            <div className="banner_images">
                <div className='inBetween_banner'>
                    <img src={prayingBanner} alt="" />
                </div>
            </div>
            <div className="row py-5">
                <div style={{ padding: "0" }}>
                    <span className='thick_dash'></span>
                </div>
                <h2 >USE OF LOGO ON COMPLEX BACKGROUNDS</h2>
                <div style={{ width: "400px", textAlign: "justify", padding: "0" }}>
                    <p >If the photographic background doesnot have a homogeneity zone, you can use the logo with the background This will avoid the logo "getting lost" on a complex background, as well as create a good contrast effect.<br /><br />The size of the logo should be atleast 1/28 and should not exceed 1/4 of the original image size.<br />The position depends on the homogenous area of the image.</p>
                </div>
                <div className="quran_pic row py-5">
                    <div className="incorret_q col-6">
                        <img src={quranPic} alt="quranpic" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="web">
                    WEB
                </div>
                <div className="prayerTiming row">
                    <img src={PrayerTiming} alt="prayerTiming" />
                </div>
            </div>
            <div className="getToMore row">
                <div className="first_col col-6">
                    <h3>Get To More<br />About Mahal</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias optio aut delectus rerum obcaecati in id cupiditate qui ab, a soluta architecto voluptates dolores. Eveniet voluptatum similique possimus numquam blanditiis. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus alias expedita adipisci magni et ad vel natus, perspiciatis voluptatum ullam explicabo molestias, sequi obcaecati. Blanditiis in nesciunt commodi ab qui.</p>
                    <button className='discover_btn'>Discover more</button>
                </div>
                <div className="second_col col-6">
                    <img src={getToMore} alt="Get to More" />
                </div>
            </div>
            <div className="banner_images">
                <div className='inBetween_banner'>
                    <img src={visitingCard} alt="" />
                </div>
            </div>
            <div className="logo_on_socialMedia row">
                <div style={{ padding: "0" }}>
                    <span className='thick_dash'></span>
                </div>
                <h2 >USE OF LOGO ON SOCIAL MEDIA</h2>
                <div style={{ width: "400px", textAlign: "justify", padding: "0" }}>
                    <p>Use Templates and design tools to create consistent and professional-looking social media graphics. This can help to save time and ensure that your design is consistent across all social media platforms.</p>
                </div>
                <div className="logo_onSocial_img row">
                    <img src={socialSS} alt="Social media screenshot" />
                </div>
            </div>
            <div className="logo_On_AppIcon row">
                <div style={{ padding: "0" }}>
                    <span className='thick_dash'></span>
                </div>
                <h2 >USE OF LOGO ON APP ICON</h2>
                <div style={{ width: "400px", textAlign: "justify", padding: "0" }}>
                    <p>Grades logo can be used in different colors for use in social media and app platforms.</p>
                </div>
                <div className="logo_onAppIcon_img row">
                    <img src={logoOnAppIcon} alt="App icon" />
                </div>
            </div>
            <div className="stationary row">
                <div style={{ padding: "0" }}>
                    <span className='thick_dash'></span>
                </div>
                <h2 >STATIONARY</h2>
                <div style={{ width: "400px", textAlign: "justify", padding: "0" }}>
                    <p>Brand stationary can be used in a variety of settings, such as business meetings, conferences, and networking events. By having consistent and professional looking stationary, a brand can create a lasting impression on clients, partners, and other stakeholders, and help to establish itself as a credible and trustworthy organization.</p>
                </div>
            </div>
            <div className="banner_imagesStationary" style={{ padding: "0" }}>
                <div className='inBetween_bannerStationary'>
                    <img src={bgofStationary} alt="" />
                    <div className="col counter-container">
                        <img src={counter1} alt="" />
                    </div>
                    <div className="row images-container">
                        <div className="col-6">
                            <img src={mug} alt="" />
                        </div>
                        <div className="col-6">
                            <img src={tshirt} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="banner_images" style={{ padding: "0" }}>
                <div className='inBetween_banner'>
                    <img src={tagMahal} alt="" />
                </div>
            </div>
            <div className="banner_images" style={{ padding: "0" }}>
                <div className='inBetween_banner'>
                    <img src={cupPic} alt="cup - picture" />
                </div>
            </div>
            {/* <div className="circle">

            </div> */}
            <div className="banner_images" style={{ padding: "0" }}>
                <div className='inBetween_banner'>
                    <img src={flag} alt="" />
                </div>
            </div>
            <div className="poly">
                <div className="innerPoly">
                    <img src={bg3} alt="" />
                    <div className="overlay-images">
                        <div className="cardcol">
                            <img src={cards} alt="" />
                        </div>
                        <div className="lettercol">
                            <img src={letter} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="certificate">
                <div className="inside_certi">
                    <img src={certificate} alt="certificate" />
                </div>
            </div>
            <div className="cards_withcode">
                <div className="inside_cardswithCode">
                    <img src={cardswithCode} alt="Cards with Code" />
                </div>
            </div>
            <div className="banner_images" style={{ padding: "0" }}>
                <div className='inBetween_banner'>
                    <img src={mosque} alt="" />
                </div>
            </div>
            <div className="thanksFor" style={{ padding: "0" }}>
                {/* <div className='thanks_for col'>
                    <><h1>THANKS</h1></>
                    <><h1 className='for'>FOR</h1></>
                </div>
                <div class="vertical-text col">
                    <span className='h3invert'><h3 >WATCHING</h3></span>
                </div> */}
                <img src={thanks} alt="" />
            </div>
            <div className="last_dash" style={{ padding: "0" }}>
                <div className="two_dashes">
                    <div className="darkBlue"></div>
                    <div className="lightBlue"></div>
                </div>
            </div>

        </div >
    )
}

export default AiMahal