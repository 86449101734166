

const dataRoadMap = [

    {
        id: 1,
        time: 'MARCH, 2017',
        title: 'Building Foundation',
        desc: 'Launched as an IT service-based company providing web development, app development, social media handling, logo branding, holistic company branding, press, and ads services. Focused on building a strong foundation and establishing an initial client base.',
        class: ''
    },
    {
        id: 2,
        time: 'APRIL, 2020',
        title: 'Adapting & Scaling',
        desc: ' Navigated the challenges of the COVID-19 pandemic, adapting to remote work while maintaining high service quality. Successfully acquired new clients in IT services, demonstrating resilience and adaptability.',
        class: 'right'
    },

    {
        id: 3,
        time: 'APRIL, 2022',
        title: 'Setting New Milestones',
        desc: " Reached a significant milestone by acquiring clients from outside India, expanding the company's global reach. Increased the size of the team by hiring more skilled professionals to meet growing demand and enhance service delivery.",
        class: ''
    },

    {
        id: 4,
        time: 'APRIL, 2023',
        title: 'Specialization & Expertise',
        desc: "Hired more skilled developers as the client base grew, particularly from regions like Qatar and Canada. Executed comprehensive projects from scratch, involving design, development, and testing phases. Continued offering holistic company branding and logo branding services to clients.",
        class: 'right'
    },
    {
        id: 5,
        time: 'APRIL, 2024',
        title: 'Leading Innovation & Growth',
        desc: " Undertaking a major project to develop a specialized vessel management website aimed at optimizing operations, enhancing navigation, safety, and resource utilization. The company continues to grow across all service areas, maintaining a trajectory of excellence and client satisfaction.",
        class: ''
    },



]

export default dataRoadMap;