import React from 'react';
import first_banner from '../assets/images/imgggg/portfolio/logo/al-akram/first_banner.webp';
import logo_anatomy from '../assets/images/imgggg/portfolio/logo/al-akram/logo_anatomy.webp';
import logo_anatomy_atc from '../assets/images/imgggg/portfolio/logo/al-akram/atc_zoomed.webp';
import combination_mark from '../assets/images/imgggg/portfolio/logo/al-akram/combination_mark.webp';
import word_mark from '../assets/images/imgggg/portfolio/logo/al-akram/word_mark.webp';
import flag_Mark from '../assets/images/imgggg/portfolio/logo/al-akram/flag_mark.webp';
import symbol_Mark from '../assets/images/imgggg/portfolio/logo/al-akram/symbol_mark.webp';
import al_akramBanner from '../assets/images/imgggg/portfolio/logo/al-akram/al akram banner.webp';
import color_palette_1 from '../assets/images/imgggg/portfolio/logo/al-akram/color_palette_1.webp';
import color_palette_2 from '../assets/images/imgggg/portfolio/logo/al-akram/color_palette_2.webp';
import color_palette_3 from '../assets/images/imgggg/portfolio/logo/al-akram/color_palette_3.webp';
import color_palette_bottom from '../assets/images/imgggg/portfolio/logo/al-akram/color_palette_bottom.webp';
import thanks_for from '../assets/images/imgggg/portfolio/logo/al-akram/thanks_for.webp';

function Aalkram() {
    return (
        <div>
            <div className='akram_body'>
                <div className="initialgrades_div">
                    <div className="grades_Col col">
                        <h1>MORE <br /> CLEARLY</h1>
                        <div>
                            <span className='thick_dash'></span>
                        </div>
                        <h2>AL AKRAM ABI AL AKRAM TRAINING CENTER BRAND BOOK</h2>
                        {/* <h2 style={{ paddingTop: "0" }}> EDUCATION SOCIETY BRAND BOOK</h2> */}
                        <p>Created by team from Exouzia.</p>
                        <p>V 1.0 No v'23</p>
                    </div>
                </div>

                <div className="banner_images" style={{ padding: "0 0 30px 0" }}>
                    <div className='inBetween_banner'>
                        <img src={first_banner} alt="" />
                    </div>
                </div>

                <div className="row" >
                    <div style={{ padding: "0" }}>
                        <span className='thick_dash'></span>
                    </div>
                    <h2>LOGO ANATOMY</h2>
                    <div className="inner_images" xs={12} lg={8}>
                        <img src={logo_anatomy} alt="Logo Concept" />
                    </div>
                    <div className="inner_images" xs={12} lg={8}>
                        <img src={logo_anatomy_atc} style={{width:"80%",padding:'2rem'}} alt="Logo Concept" />
                    </div>
                </div>

                {/* <div className="row" >
                    <div style={{ padding: "0" }}>
                        <span className='thick_dash'></span>
                    </div>
                    <h2>LOGO ANATOMY</h2>
                    <div className="inner_images" xs={12} lg={8}>
                        <img src='' style={{ padding: "50px" }} alt="Logo_Anatomy" />
                    </div>
                </div> */}

                <div className="combination_mark" >
                    <div>
                        <span className='thick_dash'></span>
                    </div>
                    <div className='logoExclusion' >
                        <h2>LOGO EXCLUSION ZONE</h2>
                        <div className='pof_logoExclusion'>
                            <p >The Exclusion is essentially for your logo to beclearly distinguishable from other graphic elements. Please avoid positioning any elements close than the defined exclusion zone.</p>
                        </div>
                    </div>
                    <h3 className='h3OfcombMark' >COMBINATION MARK</h3>
                    <div className="inner_roww">
                        <div className="col-6">
                            <div className="inner_images" xs={12} lg={8}>
                                <img src={combination_mark} alt="Logo Concept" />
                            </div>
                        </div>
                        <div className="inner_col col-6">
                            <p >1/2 of the branddark height</p>
                        </div>
                    </div>
                    <div className="wordMark row ">
                        <div className="wordMarkCol col-6">
                            <h3>WORD MARK</h3>
                            <div className="inner_images" xs={12} lg={8}>
                                <img src={word_mark} alt="Logo Concept" />
                            </div>
                        </div>
                    </div>
                    <div className="row p-0">
                        <div className="col-6" xs={6} md={6} lg={6}>
                            <h3 className='clsmark'>FLAG MARK</h3>
                            <div className="inner_images" xs={12} lg={8}>
                                <img src={flag_Mark} alt="Logo Concept" xs={6} md={6} lg={6} />
                            </div>
                        </div>
                        <div className="col-6 " xs={6} md={6} lg={6}>
                            <h3 className='clsmark'>SYMBOL MARK</h3>
                            <div className="inner_images" xs={12} lg={8}>
                                <img id='symbol_mark' xs={6} md={6} lg={6} src={symbol_Mark} alt="Logo Concept" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="banner_images">
                    <div className='inBetween_banner'>
                        <img src={al_akramBanner} alt="" />
                    </div>
                </div>

                <div className="symbolOptions row" >
                    <div style={{ padding: "0" }}>
                        <span className='thick_dash'></span>
                    </div>
                    <h2>COLOR PALETTE</h2>
                    <div className='insideSymbol d-flex'>
                        <div className="inside_img" xs={12} lg={8}>
                            <img src={color_palette_1} alt="Logo Concept" />
                        </div>
                        <div className="inside_text" xs={12} lg={8}>
                            <p style={{ color: "#0099BD" }}>#0099BD</p>
                        </div>
                    </div>
                    <div className='insideSymbol d-flex'>
                        <div className="inside_img" xs={12} lg={8}>
                            <img src={color_palette_2} alt="Logo Concept" />
                        </div>
                        <div className="inside_text" xs={12} lg={8}>
                            <p style={{ color: "#9247BD" }}>#9247BD</p>
                        </div>
                    </div>
                    <div className='insideSymbol d-flex'>
                        <div className="inside_img" xs={12} lg={8}>
                            <img src={color_palette_3} alt="Logo Concept" />
                        </div>
                        <div className="inside_text" xs={12} lg={8}>
                            <p style={{ color: "#B0742C" }}>#B0742C</p><br />
                            <p style={{ color: "#EBCD7C" }}>#EBCD7C</p>
                        </div>
                    </div>
                    {/* <div className='insideSymbol d-flex'>
                        <div className="inside_img" xs={12} lg={8}>
                            <img src='' alt="Logo Concept" />
                        </div>
                        <div className="inside_text" xs={12} lg={8}>
                            <h4>Dark on White</h4><br />
                            <p>Dark version of the symbol should be used only for one-color printing.</p>
                        </div>
                    </div> */}
                </div>

                <div className="banner_images" style={{ padding: "0" }}>
                    <div className='inBetween_banner'>
                        <img src={color_palette_bottom} style={{ padding: "5rem" }} alt="" />
                    </div>
                </div>

                <div className="color_palette row" >
                    <div style={{ padding: "0" }}>
                        <span className='thick_dash'></span>
                    </div>
                    <h2>COLOR PALETTE</h2>
                    <div className='palette_color'></div>

                    <div className="d-flex justify-content-between pb-5">
                        <div className="hex_values">
                            Hex :   #005689 <br />
                            RGB :   0, 186, 137 <br />
                            CMYK :  98, 69, 22, 6
                        </div>
                        <div className="hex_values">
                            Hex :   #359688 <br />
                            RGB :   53, 150, 36 <br />
                            CMYK :  77, 21, 53, 2
                        </div>
                    </div>
                </div>


                <div className="thanksFor" style={{ padding: "0" }}>
                    {/* <div className='thanks_for col'>
                    <><h1>THANKS</h1></>
                    <><h1 className='for'>FOR</h1></>
                </div>
                <div class="vertical-text col">
                    <span className='h3invert'><h3 >WATCHING</h3></span>
                </div> */}
                    <img src={thanks_for} alt="" />
                </div>
                <div className="last_dash" style={{ padding: "0" }}>
                    <div className="two_dashes">
                        <div className="darkBlue"></div>
                    </div>
                </div>
            </div >
        </div>
    )
}

export default Aalkram