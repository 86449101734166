import React from 'react'
import one from '../assets/images/imgggg/portfolio/logo/yazir/lgoo.webp'
import LogoConcept from '../assets/images/imgggg/portfolio/logo/yazir/logo_concept.webp'
import two from '../assets/images/imgggg/portfolio/logo/yazir/img22.webp'
import three from '../assets/images/imgggg/portfolio/logo/yazir/img23.webp'
import four from '../assets/images/imgggg/portfolio/logo/yazir/img24.webp'
import five from '../assets/images/imgggg/portfolio/logo/yazir/first.webp'


function Yazia() {
  return (
    <div>
      <div className="yazir_body">
        <div className="somespace" style={{ padding: "4vh 0" }}></div>
        <div className="top_div">
          <div className="first_dash">
            <div className="first_blue">
            </div>
            <div className="second_blue">
            </div>
          </div>
          <div className="textRow row">
            <span><p>Blue: Often associated with trust, reliability and professionalism. Blue is a common choice for companies in the industrial and construction sectors.</p></span>
          </div>
          <div className="firstLogo row">
            <img src={one} alt="" />
          </div>
          <div className="logo_Concept row">
            <img src={LogoConcept} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{padding:"0"}}>
          <div className='inBetween_banner'>
            <img src={five} alt="" />
          </div>
        </div>
        <div className="banner_images">
          <div className='inBetween_banner'>
            <img src={two} alt="" />
          </div>
        </div>
        <div className="banner_images">
          <div className='inBetween_banner'>
            <img src={three} alt="" />
          </div>
        </div>
        <div className="banner_images">
          <div className='inBetween_banner'>
            <img src={four} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Yazia