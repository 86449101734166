import React, { useState } from 'react';

import PageTitle from '../components/pagetitle/PageTitle';
import Counter from '../components/counter/Counter';
import Footer from '../components/footer/Footer';
import Faqs from '../components/faqs/Faqs';
import dataFaqs from '../assets/fake-data/data-faq';

import img1 from '../assets/images/imgggg/layouts/business_reduced.webp'
import img2 from '../assets/images/imgggg/layouts/satellite.webp'
import img3 from '../assets/images/imgggg/layouts/visionpro.webp'
import img4 from '../assets/images/imgggg/layouts/world.webp'
import img5 from '../assets/images/imgggg/layouts/man.webp'



function VisionsMission(props) {

    const [dataImg] = useState([
        {
            id: 1,
            img: img1,
            class: 'img1'
        },
        {
            id: 2,
            img: img2,
            class: 'img2'
        },
        {
            id: 3,
            img: img3,
            class: 'img3'
        },
        {
            id: 4,
            img: img4,
            class: 'img4'
        },
        {
            id: 5,
            img: img5,
            class: 'img5'
        },
    ])
    return (
        <div className='page-visions wrapper'>

            <PageTitle title='Visions & Mission' />
            <section className="about">
                <div className="container">
                    <div className="row">

                        <div className="col-xl-6 col-md-12">
                            <div className="block-text">
                                <h6 className="sub-heading"><span>Visions & Mission</span></h6>
                                <h3 className="heading wow" data-splitting>Our Vision</h3>
                                <p className="mb-17">To be recognized as one of the best software solution providers in the industry that creates the integrated, secured as well as affordable technology environments with the back up of rapid, diverse and collaborative forces of IT experts.</p>
                            </div>
                            <div className="block-text">
                                <h6 className="sub-heading"><span>Visions & Mission</span></h6>
                                <h3 className="heading wow" data-splitting>Our Mission</h3>
                                {/* <p>To deliver our customers the world class, innovative and cost effective IT & ITES products and services with uncompromising values and commitment.</p><br />
                                <p>To retain a culture of collaboration,trust, openness and accountability in our organisation to help us in building the products of global standards.</p><br />
                                <p>To become a truly unique company by implementing a customer-first strategy to drive each and every business transaction.</p><br />
                                <p>To make a positive impact on human life and businesses through our ingenious and cutting edge technology solutions.. </p><br />
                                <p>To encourage feedback and embrace criticism as opportunities for improvement and uphold these qualities when engaging with customers and partners. </p><br />
                                <p>To continually strive to create an environment that promotes the theory of working smart that will be achieved through the incessant hiring of brilliant, passionate and motivated people from diverse backgrounds.</p> */}
                               <p>Our mission is to deliver world-class IT & ITES products and services with unwavering commitment, fostering a culture of collaboration and accountability</p><br/>
                               <p>We prioritize customer needs through a customer-first strategy, aiming to make a positive impact on human life and businesses with cutting-edge technology solutions</p><br/>
                               <p>Embracing feedback and criticism, we continuously improve and strive to create a diverse environment that promotes smart work through passionate recruitment.</p><br/>
                               
                                {/* <p>Cyfonii is conceptualized almost like the development of the digital society and digital economy of the world according to the comprehensive digital transformation model (Digital Transformation) in the period of Industry 4.0 taking place very strongly all over the world starting from the digitization period (Digitization) to the goal of building a virtual super universe (Metaverse) is being shaped.</p> */}
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="about__right">
                                <div className="images">
                                    {
                                        dataImg.map(idx => (
                                            <img key={idx.id} className={idx.class} src={idx.img} alt="exouzia" />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Counter />

            <Faqs data={dataFaqs} />


            <Footer />
        </div>
    );
}

export default VisionsMission;