import React , {useState} from 'react';
import Button from '../button/Button';

import img from '../../assets/images/imgggg/layouts/create globe.webp'



function Create(props) { 

    
    const [dataBlock] = useState(
        {
            heading: 'Lets Build Something amazing together',
            desc: 'Our expertise meets your needs !',
            
        }
    )
    return (
        <section className="create">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="create__main">
                                <div className="content">
                                    <h4 className="heading">{dataBlock.heading}</h4>
                                    <p>{dataBlock.desc}</p>
                                    <Button title='Start a Project' link='/contact' />
                                
                                </div>
                                <img src={img} alt="exouzia" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default Create;