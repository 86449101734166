
import React, { useState } from "react";
import PageTitle2 from '../components/pagetitle/PageTitle2'
import Footer from '../components/footer/Footer';
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import { Link } from 'react-router-dom';
import img1 from '../assets/images/imgggg/layouts/marketing 4.webp'
import cocofina from "../assets/images/imgggg/demo/cocofino.webp"
import izan from '../assets/images/imgggg/works/izan-digital-mahal.webp'
import gloduate from '../assets/images/imgggg/works/gloduate.webp'
import PATHRADHI from '../assets/images/imgggg/demo/WhatsApp Image 2024-07-11 at 11.47.21 AM.webp'
import medimall from '../assets/images/imgggg/demo/medimall.webp'
import aata from '../assets/images/imgggg/demo/aata2.webp'
import nutrileaves from '../assets/images/imgggg/demo/WhatsApp Image 2024-07-11 at 11.59.37 AM.webp'

import gsap from "gsap";
import Transition from "../components/transitions/transition";


function Marketing(props) {

    const [dataBlock] = useState(
        {
            subheading: 'Our services',
            heading: 'Social Media Page Handling',

        }
    )

    const [dataImg] = useState([
        {
            id: 1,
            img: img1,
            class: 'img1'
        },
        // {
        //     id: 2,
        //     img: img2,
        //     class: 'img2'
        // },
        // {
        //     id: 3,
        //     img: img3,
        //     class: 'img3'
        // },
        // {
        //     id: 4,
        //     img: img4,
        //     class: 'img4'
        // },
        // {
        //     id: 5,
        //     img: img5,
        //     class: 'img5'
        // },
    ])

    const [products] = useState([
        {
            id: 1,
            heading: "Izan Digital Mahal",
            subheading: 'The central initiative revolving around the mahals of Kerala',
            link: '',
            img: izan,
            desc: 'Streamline mahal management with intuitive features, scheduling tools, and analytics for enhanced efficiency and organization.',
            count: 235
        },
        {
            id: 2,
            heading: 'Gloduate',
            subheading: 'CareerPulse: Energizing Your Professional Journey',
            link: '',
            img: gloduate,
            desc: "Your career roadmap starts here. Tailored guidance, personalized strategies, and expert advice to propel you towards your dream job.",
            count: 150
        },
        {
            id: 3,
            heading: 'PATHRADHI',
            subheading: "Nourish, Strengthen, and Shine",
            link: '',
            img: PATHRADHI,
            desc: 'Experience the power of nature with our premium hair oil, designed to nourish, strengthen, and add shine to your hair for a healthier, lustrous look.',
            count: 28
        },
        {
            id: 4,
            heading: 'MEDIMALL',
            subheading: "Your Trusted Partner in Health",
            link: '',
            img: medimall,
            desc: 'Empowering your health with cutting-edge medicinal solutions designed for optimal well-being and a brighter, healthier future..',
            count: 28
        },
        {
            id: 5,
            heading: 'AATAA HOLIDAYS',
            subheading: "subheading",
            link: '',
            img: aata,
            desc: 'description.',
            count: 28
        },
        {
            id: 6,
            heading: 'COCOFINO',
            subheading: "subheading",
            link: '',
            img: cocofina,
            desc: 'description.',
            count: 28
        },
        {
            id: 7,
            heading: 'SEVA GRAM ',
            subheading: "subheading",
            link: '',
            img: img1,
            desc: 'description.',
            count: 28
        },
        {
            id: 8,
            heading: 'NUTRI LEAVES',
            subheading: "subheading",
            link: '',
            img: nutrileaves,
            desc: 'description.',
            count: 28
        }
    ])

    const [counterOn, setCounterOn] = useState(false);
    const [counterOn1, setCounterOn1] = useState(false);
    const [counterOn2, setCounterOn2] = useState(false);
    const brand = gsap.timeline()
    return (
        <div>
            <Transition timeline={brand} />
            <div className="page-visions wrapper">
                <PageTitle2 title='Digital Marketing' />
                <section className="aboutsp">
                    <div className="container">
                        <div className="row">

                            <div className="col-xl-6 col-md-12">
                                <div className="block-text">
                                    <h6 className="sub-heading"><span>Digital Marketing</span></h6>
                                    <h3 className="heading wow" data-splitting>Revolutionize Branding: Our Marketing Services</h3>
                                    <p className="mb-17">Unlock your brand's full potential with our comprehensive digital marketing services.From SEO, social media management, PPC, and email marketing to Google Ads, video branding, content digitalization, and business listing marketing, we offer a holistic approach to online promotion.<p><br /> Our expert team combines strategic planning with data-driven insights to craft tailored campaigns that resonate and drive results. </p><br />Whether you aim to increase website traffic, boost conversions, or enhance brand visibility, we have the expertise and resources to achieve your goals. <br />Plus, we specialize in creative ad campaigns, conversion rate optimization, and reel podcast marketing.
                                        <p><br />Additionally, we provide services such as brochures, newsletters, banners, and posters to amplify your brand's presence across all channels.</p> <p><br />Partner with us for maximum impact and long-term success.</p></p>
                                </div>

                            </div>
                            <div className="col-xl-6 col-md-12">
                                <div className="about__right">
                                    <div className="images">
                                        {
                                            dataImg.map(idx => (
                                                <img key={idx.id} className={idx.class} src={idx.img} alt="exouzia" />
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="nft">
                    {/* <div className="shape right"></div> */}
                    <div className="container">
                        <div className="row" style={{ justifyContent: 'center' }}>
                            <div className="col-12">
                                <div className="block-text center">
                                    <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                    <h3 className="heading " >{dataBlock.heading}</h3>
                                </div>
                            </div>

                            {/* //.....................previous code............................. classname: project sp */}

                            {/* {
                            products.slice(0,3).map(idx => (
                                <div key={idx.id} className="col-xl-4 col-md-6 " style={{ marginBottom: '10px' }}>
                                    <div className="project-boxx">
                                        <div className="image">
                                            <Link to={idx.link}>
                                                <img src={idx.img} alt="exouzia" />
                                            </Link>
                                        </div>
                                      
                                    </div>
                                    <h6 style={{ fontSize: '25px', display: 'flex', justifyContent: 'start', paddingLeft: '25px' }}>{idx.heading}</h6>
                                    <p style={{ fontSize: '16px', display: 'flex', justifyContent: 'start', paddingLeft: '25px' }}>{idx.subheading}</p>
                                    <p style={{ paddingTop: '18px', paddingLeft: '25px', paddingBottom: '20px' }}>{idx.desc}</p>
                                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                    <h6 style={{ fontSize: '25px'}}>{counterOn && <CountUp start={0} end={idx.count} duration={2} delay={0} />}+</h6>
                                    </ScrollTrigger>
                                </div>
                            ))
                        }
                        {
                            products.slice(3,6).map(idx => (
                                <div key={idx.id} className="col-xl-4 col-md-6 " style={{ marginBottom: '10px' }}>
                                    <div className="project-boxx">
                                        <div className="image">
                                            <Link to={idx.link}>
                                                <img src={idx.img} alt="exouzia" />
                                            </Link>
                                        </div>
                                       
                                    </div>
                                    <h6 style={{ fontSize: '25px', display: 'flex', justifyContent: 'start', paddingLeft: '25px' }}>{idx.heading}</h6>
                                    <p style={{ fontSize: '16px', display: 'flex', justifyContent: 'start', paddingLeft: '25px' }}>{idx.subheading}</p>
                                    <p style={{ paddingTop: '18px', paddingLeft: '25px', paddingBottom: '20px' }}>{idx.desc}</p>
                                    <ScrollTrigger onEnter={() => setCounterOn1(true)} onExit={() => setCounterOn1(false)}>
                                        <h6 style={{ fontSize: '25px'}}>{counterOn1 && <CountUp start={0} end={idx.count} duration={2} delay={0} />}+</h6>
                                    </ScrollTrigger>
                                </div>
                            ))
                        } */}

                            {/* //.....................previous code............................. */}
                            {
                                products.map(idx => (
                                    <div key={idx.id} className="col-xl-4 col-md-6">
                                        <div className="nft-item">
                                            <div className="card-media">
                                                <Link to="#"><img src={idx.img} alt="exouzia" /></Link>
                                            </div>
                                            <div className="card-title1">
                                                <Link to="#" className="h5">{idx.heading}</Link>
                                            </div>
                                            <div className="meta-info">
                                                <div>
                                                    <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'start' }}>{idx.subheading}</p>
                                                    <p style={{ paddingTop: '6px', height: '85px' }}>{idx.desc}</p>
                                                </div>
                                            </div>
                                            {/* <div className="card-bottom style-explode">
                                                <div className="price">
                                                    <span className="icon-logo-01"></span>
                                                    <div className='countplus'>
                                                        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                                            <h6 style={{ fontSize: '20px' }}>{counterOn && <CountUp start={0} end={idx.count} duration={2} delay={0} />}+</h6>
                                                        </ScrollTrigger>
                                                        <div className="meta-info">
                                                            <div className="author">
                                                                <div className="info1">
                                                                    <span>Happy customers</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div> */}
                                        </div>
                                    </div>
                                ))
                            }
                            {/* {
                            products.slice(3, 6).map(idx => (
                                <div key={idx.id} className="col-xl-4 col-md-6">
                                    <div className="nft-item">
                                        <div className="card-media">
                                            <Link to="#"><img src={idx.img} alt="exouzia" /></Link>
                                        </div>
                                        <div className="card-title1">
                                            <Link to="#" className="h5">{idx.heading}</Link>
                                        </div>
                                        <div className="meta-info">
                                            <div>
                                                <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'start' }}>{idx.subheading}</p>
                                                <p style={{ paddingTop: '6px', height: '85px' }}>{idx.desc}</p>
                                            </div>
                                        </div>
                                        <div className="card-bottom style-explode">
                                            <div className="price">
                                                <span className="icon-logo-01"></span>
                                                <div className='countplus'>
                                                    <ScrollTrigger onEnter={() => setCounterOn1(true)} onExit={() => setCounterOn1(false)}>
                                                        <h6 style={{ fontSize: '20px' }}>{counterOn1 && <CountUp start={0} end={idx.count} duration={2} delay={0} />}+</h6>
                                                    </ScrollTrigger>
                                                    <div className="meta-info">
                                                        <div className="author">
                                                            <div className="info1">
                                                                <span>Happy customers</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        } */}


                        </div>
                    </div>
                </section>
                <Footer />

            </div>
        </div>
    );
}
export default Marketing;