import React from 'react'
import one from '../assets/images/imgggg/portfolio/logo/tis/tis c p 5_page-0001.webp'
import two from '../assets/images/imgggg/portfolio/logo/tis/tis c p 5_page-0002.webp'
import three from '../assets/images/imgggg/portfolio/logo/tis/tis c p 5_page-0003.webp'
import four from '../assets/images/imgggg/portfolio/logo/tis/tis c p 5_page-0004.webp'
import five from '../assets/images/imgggg/portfolio/logo/tis/tis c p 5_page-0005.webp'
import six from '../assets/images/imgggg/portfolio/logo/tis/tis c p 5_page-0006.webp'
import seven from '../assets/images/imgggg/portfolio/logo/tis/tis c p 5_page-0007.webp'
import eight from '../assets/images/imgggg/portfolio/logo/tis/tis c p 5_page-0008.webp'
import nine from '../assets/images/imgggg/portfolio/logo/tis/tis c p 5_page-0009.webp'
import ten from '../assets/images/imgggg/portfolio/logo/tis/tis c p 5_page-0010.webp'
import eleven from '../assets/images/imgggg/portfolio/logo/tis/tis c p 5_page-0011.webp'
import twelve from '../assets/images/imgggg/portfolio/logo/tis/tis c p 5_page-0012.webp'
import thirteen from '../assets/images/imgggg/portfolio/logo/tis/tis c p 5_page-0013.webp'
import fourteen from '../assets/images/imgggg/portfolio/logo/tis/tis c p 5_page-0014.webp'
import fifteen from '../assets/images/imgggg/portfolio/logo/tis/tis c p 5_page-0015.webp'
import sixteen from '../assets/images/imgggg/portfolio/logo/tis/tis c p 5_page-0016.webp'
import seventeen from '../assets/images/imgggg/portfolio/logo/tis/tis c p 5_page-0017.webp'
import eighteen from '../assets/images/imgggg/portfolio/logo/tis/tis c p 5_page-0018.webp'
import nineteen from '../assets/images/imgggg/portfolio/logo/tis/tis c p 5_page-0019.webp'
import twenty from '../assets/images/imgggg/portfolio/logo/tis/tis c p 5_page-0020.webp'


function Tis() {
  return (
    <div>
      <div className='endora'>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={one} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={two} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={three} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={four} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={five} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={six} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={seven} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={eight} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={nine} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={ten} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={eleven} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={twelve} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={thirteen} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={fourteen} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={fifteen} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={sixteen} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={seventeen} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={eighteen} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={nineteen} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={twenty} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tis