// import React from "react";
// import PageTitle2 from '../components/pagetitle/PageTitle2'
// import Footer from '../components/footer/Footer';


// function Seo(props) {
//     return (
//         <div className="wrapper">
//             <PageTitle2 title = 'SEO Consulting Services' />
//             <Footer/>

//         </div>
//     );
// }
// export default Seo;

import React, { useState } from "react";
import PageTitle2 from '../components/pagetitle/PageTitle2'
import Footer from '../components/footer/Footer';

import img1 from '../assets/images/imgggg/layouts/seo3.webp'
import gsap from "gsap";
import Transition from "../components/transitions/transition";

function Seo(props) {
    const [dataImg] = useState([
        {
            id: 1,
            img: img1,
            class: 'img1'
        },
        // {
        //     id: 2,
        //     img: img2,
        //     class: 'img2'
        // },
        // {
        //     id: 3,
        //     img: img3,
        //     class: 'img3'
        // },
        // {
        //     id: 4,
        //     img: img4,
        //     class: 'img4'
        // },
        // {
        //     id: 5,
        //     img: img5,
        //     class: 'img5'
        // },
    ])
    const brand = gsap.timeline()

    return (
        <div>
            <Transition timeline={brand} />

            <div className="page-visions wrapper">
                <PageTitle2 title='SEO consulting Services' />
                <section className="about">
                    <div className="container">
                        <div className="row">

                            <div className="col-xl-6 col-md-12">
                                <div className="block-text">
                                    <h6 className="sub-heading"><span>SEO consulting Services</span></h6>
                                    <h3 className="heading wow" data-splitting>Guiding Your Digital Success</h3>
                                    <p className="mb-17">Experience the power of strategic SEO consulting services tailored to drive your online success.  <p><br />Our comprehensive approach covers every aspect of SEO, including e-commerce SEO, mobile and video SEO, local and multi-regional SEO, and link auditing penalties removal.</p> <p><br /> We specialize in industry-specific SEO strategies, competitive analysis, technical SEO optimization, international SEO, and one-page SEO solutions.</p> With our expert guidance, you'll maximize your online visibility, attract targeted traffic, and achieve higher rankings in search engine results. <p><br /> Partner with us to unlock the full potential of your online presence and stay ahead of the competition in today's digital landscape.</p>   </p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-12">
                                <div className="about__right">
                                    <div className="images">
                                        {
                                            dataImg.map(idx => (
                                                <img key={idx.id} className={idx.class} src={idx.img} alt="exouzia" />
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />

            </div>
        </div>
    );
}
export default Seo;