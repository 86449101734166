import React, { useState } from 'react';
import PropTypes from 'prop-types';

import img from '../../assets/images/imgggg/layouts/Designer__26_-removebg.webp'
import { Link } from 'react-router-dom';

Portfolio.propTypes = {
    data: PropTypes.array
};

function Portfolio(props) {

    const { data } = props;

    const [dataBlock] = useState(
        {
            subheading: 'OUR BEST SERVICES',
            heading: 'Unlocking the Potential of Your Success',
        }
    )
    return (
        <section className="portfolio">
            <div className="shape"></div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center">
                            <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                            <h3 className="heading pd">{dataBlock.heading}</h3>
                        </div>
                    </div>
                   
                    <div className="col-xl-4 col-md-4 col-xs-12" style={{display:'flex',justifyContent:'end'}}>
                        <div className="portfolio__right"  >
                            {
                                data.slice(0, 3).map(idx => (
                                <Link to={idx.link} style={{textDecoration:'none'}}>
                                        
                                    <div key={idx.id} className="portfolio-box" data-aos="fade-right" data-aos-duration="1500">
                                        <div className="content">
                                            <h5 className="title" style={{display:'flex',justifyContent:'end'}}>{idx.title}</h5>
                                            <p style={{fontFamily:'"PT Sans", sans-serif'}}>{idx.desc}</p>
                                        </div>
                                        <div className="step" style={{display:'flex',justifyContent:'end'}}>
                                            
                                            {idx.id}</div>
                                        <div className="icon" >
                                            <img src={idx.img} alt="exouzia" />
                                        </div>
                                    </div>
                                </Link>
                                ))
                            }

                        </div>
                    </div>
                    {/* <div className="col-xl-4 col-md-4" style={{display:'flex',justifyContent:'start'}}> */}
                    <div className="col-xl-4 col-md-4" style={{display:'flex',justifyContent:'center'}}>
                        <div
                         className="portfolio__right"
                         >
                            <div style={{height:"635px",width:'355px',objectFit:'cover'}} className="image" data-aos="fade-left" data-aos-duration="2000">
                                <img style={{height:"635px",width:'355px',objectFit:'cover'}} src={img} alt="exouzia" />
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-md-4 col-xs-12">
                        <div className="portfolio__right"  >
                            {
                                data.slice(3, 6).map(idx => (
                                <Link to={idx.link}>
                                    <div key={idx.id} className="portfolio-box1" data-aos="fade-left" data-aos-duration="1500">
                                        <div className="step1">
                                            
                                            {idx.id}</div>
                                        <div className="icon1">
                                            <img src={idx.img} alt="exouzia" />

                                        </div>
                                        <div className="content1">
                                            <h5 className="title1">{idx.title}</h5>
                                            <p style={{fontFamily:'"PT Sans", sans-serif'}}>{idx.desc}</p>
                                        </div>
                                    </div>
                                </Link>
                                ))
                            }

                        </div>
                    </div>
                





                </div>
            </div>
        </section>
    );
}

export default Portfolio;

//....................................................//

// import React , {useState} from 'react';
// import PropTypes from 'prop-types';

// import img from '../../assets/images/imgggg/layouts/portfolio.webp'

// Portfolio.propTypes = {
//     data: PropTypes.array
// };

// function Portfolio(props) {

//     const {data} = props;

//     const [dataBlock] = useState(
//         {
//             subheading: 'OUR BEST SERVICES',
//             heading: 'Unlocking the Potential of Your Success',
            
//         }
//     )
//     return (
//         <section className="portfolio">
//                 <div className="shape"></div>
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-12">
//                             <div className="block-text center">
//                                 <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
//                                 <h3 className="heading pd">{dataBlock.heading}</h3>
                                    
//                             </div>
//                         </div>
//                         <div className="col-xl-6 col-md-6">
//                             <div className="portfolio__left">
//                                 {
//                                     data.map(idx => (
//                                         <div key={idx.id} className="portfolio-box">
//                                             <div className="step">
//                                                 {/* Step  */}
//                                             {idx.id}</div>
//                                             <div className="icon">
//                                                 <img src={idx.img} alt="exouzia" />
                                                    
//                                             </div>
//                                             <div className="content">
//                                                 <h5 className="title">{idx.title}</h5>
//                                                 <p>{idx.desc}</p>
//                                             </div>
//                                         </div>
//                                     ))
//                                 }
                                
//                             </div>
//                         </div>

//                         <div className="col-xl-6 col-md-6">
//                             <div className="portfolio__right">
//                                 <div className="image" data-aos="fade-left" data-aos-duration="2000">
//                                     <img src={img} alt="exouzia" />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//     );
// }

// export default Portfolio;