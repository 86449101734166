
import nas from '../images/logo/nas.png'
import badar from '../images/logo/badar.png'
import alman from '../images/logo/amlan.png'
import technocrat from '../images/logo/technocratman.png'
import meshart from '../images/logo/meshart.png'
import getTravel from '../images/logo/get_travel.png'
import gloduate from '../images/logo/gloduate.png'
import jasbin from '../images/logo/jasbin.png'


const dataPartner = [

    {
        id: 1,
        img: nas,
    },
    {
        id: 2,
        img: badar,
    },
    {
        id: 3,
        img: alman,
    },
    {
        id: 4,
        img: technocrat,
    },
    {
        id: 5,
        img: meshart,
    },
    {
        id: 6,
        img: getTravel,
    },{
        id: 7,
        img: gloduate,
    },{
        id: 8,
        img: jasbin,
    },
    

]

export default dataPartner;