const menus = [
    {
        id: 1,
        name: 'Home',
        // links: '#',
        links: '/',
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'Home 01',
        //         links: '/'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Home 02',
        //         links: '/home-v2'
        //     },
        //     {
        //         id: 3,
        //         sub: 'Home 03',
        //         links: '/home-v3'
        //     },

        // ]
    },
    {
        id: 2,
        // name: 'Explore',
        name:'Vision & Mission',
        links: '/visions-mission',
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'NFTs',
        //         links: '/nfts'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Blog List',
        //         links: '/blog'
        //     },
        //     {
        //         id: 3,
        //         sub: 'Blog Details',
        //         links: '/blog-details'
        //     },
        //     {
        //         id: 4,
        //         sub: 'Visions & Mission',
        //         links: '/visions-mission'
        //     },
        //     {
        //         id: 5,
        //         sub: 'Help Center',
        //         links: '/help-center'
        //     },
        //     {
        //         id: 6,
        //         sub: 'Participants & Assets',
        //         links: '/participants-assets'
        //     },
        //     // {    
        //     //     id: 7,
        //     //     sub: 'Advisors & Backers',
        //     //     links: '/advisors-backers'
        //     // },
        //     {
        //         id: 8,
        //         sub: 'Partners & Investors',
        //         links: '/partners'
        //     },
        // ]
    },
    {
        id: 4,
        name: 'About',
        links: '/about',
        
    },
    // {
    //     id: 5,
    //     name: 'Services',
    //     links: '#',
    //     namesub:[
    //         {
    //             id : 1,
    //             sub : 'Software Development',
    //             links :'/software-development'
    //         },
    //         {
    //             id : 2,
    //             sub : 'Website Development',
    //             links :'/Website-development'
    //         },
    //         {
    //             id : 3,
    //             sub : 'Application Development',
    //             links :'/Application-development'
    //         },
    //         {
    //             id : 4,
    //             sub : 'Digital Marketing',
    //             links :'/digital-marketing'
    //         },
    //         {
    //             id : 5,
    //             sub : 'Wholistic Company Branding',
    //             links :'/company-branding'
    //         },
    //         {
    //             id : 6,
    //             sub : 'SEO Consulting Services',
    //             links :'/seo-consulting'
    //         },
    //     ]
        
    // },
    {
        id: 5,
        name: 'RoadMap',
        links: '/road-map',
        
    },
    {
        id: 6,
        name: 'Services',
        links: '/Services',
        
    },
    {
        id: 7,
        name: 'Team',
        links: '/team',
        
    },
    {
        id: 8,
        name: 'Contact',
        links: '/contact',
    },

    
]

export default menus;