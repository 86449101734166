import React, { useState } from "react";
import Footer from '../components/footer/Footer';
import { Link } from 'react-router-dom';
import Button from '../components/button/Button';
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
// import ScrollMagic from 'scrollmagic';

// import img1 from '../assets/images/imgggg/background/Screenshot (187).webp'

function Products() {

    const [products] = useState(
        {
            id: 1,
            heading: 'product ',
            subheading: '',
            link: '',
            img: '',
            desc: ''
        },
        {
            id: 2,
            heading: 'product 2 ',
            subheading: '',
            link: '',
            img: '',
            desc: ''
        },
        {
            id: 3,
            heading: 'product 3 ',
            subheading: '',
            link: '',
            img: '',
            desc: ''
        },
        {
            id: 4,
            heading: 'product 4 ',
            subheading: '',
            link: '',
            img: '',
            desc: ''
        },
        {
            id: 5,
            heading: 'product 5 ',
            subheading: '',
            link: '',
            img: '',
            desc: ''
        },
        {
            id: 6,
            heading: 'product 6 ',
            subheading: '',
            link: '',
            img: '',
            desc: ''
        },
        {
            id: 7,
            heading: 'product 7 ',
            subheading: '',
            link: '',
            img: '',
            desc: ''
        },
        {
            id: 8,
            heading: 'product 8 ',
            subheading: '',
            link: '',
            img: '',
            desc: ''
        },
        {
            id: 9,
            heading: 'product 9 ',
            subheading: '',
            link: '',
            img: '',
            desc: ''
        },
        {
            id: 10,
            heading: 'product 10 ',
            subheading: '',
            link: '',
            img: '',
            desc: ''
        },
        {
            id: 11,
            heading: 'product 11 ',
            subheading: '',
            link: '',
            img: '',
            desc: ''
        },
        {
            id: 12,
            heading: 'product 12 ',
            subheading: '',
            link: '',
            img: '',
            desc: ''
        },
    )

    const [projects] = useState([
        {
            id: 1,
            heading: "Izan Digital Mahal & Smart Da'wa",
            subheading: 'The central initiative revolving around the mahals of Kerala',
            link: '',
            // img: img1,
            desc: 'Streamline mahal management with intuitive features, scheduling tools, and analytics for enhanced efficiency and organization.',
            count: 235
        },
        {
            id: 2,
            heading: 'Cybrix & Restro',
            subheading: 'Most Trusted ERP Software In Middle East ,Africa & Europe',
            link: '',
            // img: img1,
            desc: 'Elevate your business with our ERP solution. Effortlessly integrate POS, access advanced reporting tools, and efficiently manage payroll and HR tasks.',
            count: 100
        },
        {
            id: 3,
            heading: 'AI Mahal',
            subheading: "Foremost enterprise within Kerala's enchanting realm",
            link: '',
            // img: img1,
            desc: 'Empowering mahal administrators with smart tools for seamless operations, scheduling, and data-driven decision-making in a user-friendly interface.',
            count: 28
        },
        {
            id: 4,
            heading: 'Rubiz Hotel Management',
            subheading: 'Simplifying Hotel Operations',
            link: '',
            // img: img1,
            desc: 'Discover the pinnacle of hotel management software, designed to elevate your hospitality business to new heights effortlessly.',
            count: 350
        },
        {
            id: 5,
            heading: 'Passdaily',
            subheading: 'Modernizing Education: Harnessing Technology for Efficiency',
            link: '',
            // img: img1,
            desc: 'Unlock the future of education with our innovative software solution. Seamlessly manage attendance, drive business growth, and enhance communication with our SMS services.',
            count: 150
        },
        {
            id: 6,
            heading: 'VOIP & VPN',
            subheading: 'Streamline Communication and Security with VoIP & VPN',
            link: '',
            // img: img1,
            desc: 'Experience seamless communication and enhanced security with our software. Utilize VoIP for clear calls and VPN for privacy. Simplify connectivity with our integrated solution.',
            count: 900
        }
    ])

    const [counterOn, setCounterOn] = useState(false);
    const [counterOn1, setCounterOn1] = useState(false);
    const [counterOn2, setCounterOn2] = useState(false);

    // var controller = new ScrollMagic.Controller();

    // // Create a scene for your animation
    // var scene = new ScrollMagic.Scene({
    //     triggerElement: ".header-content", // Element that triggers the animation
    //     triggerHook: "onEnter", // Adjust as needed
    //     offset: -400 // Offset from the trigger point
    // })
    // .setClassToggle(".header-content", "fade-in") // Add a class when the scene is in view
    // .addTo(controller);

    return (

        <div 
        // className="page-visions wrapper"
        >
            <div className="productimg">
                <div className="bg-heading">
                    <div className="row header-content">
                        <h1 className="header-content1"> Building Next-Gen Software Solutions</h1>
                        <h6  className="header-content1">The development team at exouzia is proficient in a variety of development tools and operating environments. </h6>
                    </div>
                </div>
            </div>
            <section style={{marginTop:'250px'}} className="nft">
                {/* <div className="shape right"></div> */}
                <div className="container">
                    <div className="row">
                        
                        {
                            projects.slice(0, 3).map(idx => (
                                <div key={idx.id} className="col-xl-4 col-md-6">
                                    <div className="nft-item">
                                        <div className="card-media">
                                            <Link to="#">
                                            {/* <img src={idx.img} alt="exouzia" /> */}
                                            </Link>
                                        </div>
                                        <div className="card-title1">
                                            <Link to="#" className="h5">{idx.heading}</Link>
                                        </div>
                                        <div className="meta-info">
                                            <div>
                                                <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'start' }}>{idx.subheading}</p>
                                                <p style={{ paddingTop: '6px', height: '85px' }}>{idx.desc}</p>
                                            </div>
                                        </div>
                                        {/* <div className="card-bottom style-explode">
                                            <div className="price">
                                                <span className="icon-logo-01"></span>
                                                <div className='countplus'>
                                                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                                        <h6 style={{ fontSize: '20px' }}>{counterOn && <CountUp start={0} end={idx.count} duration={2} delay={0} />}+</h6>
                                                    </ScrollTrigger>
                                                    <div className="meta-info">
                                                        <div className="author">
                                                            <div className="info1">
                                                                <span>Happy customers</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div> */}
                                    </div>
                                </div>
                            ))
                        }
                        {
                            projects.slice(3, 6).map(idx => (
                                <div key={idx.id} className="col-xl-4 col-md-6">
                                    <div className="nft-item">
                                        <div className="card-media">
                                            <Link to="#"><img src={idx.img} alt="exouzia" /></Link>
                                        </div>
                                        <div className="card-title1">
                                            <Link to="#" className="h5">{idx.heading}</Link>
                                        </div>
                                        <div className="meta-info">
                                            <div>
                                                <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'start' }}>{idx.subheading}</p>
                                                <p style={{ paddingTop: '6px', height: '85px' }}>{idx.desc}</p>
                                            </div>
                                        </div>
                                        {/* <div className="card-bottom style-explode">
                                            <div className="price">
                                                <span className="icon-logo-01"></span>
                                                <div className='countplus'>
                                                    <ScrollTrigger onEnter={() => setCounterOn1(true)} onExit={() => setCounterOn1(false)}>
                                                        <h6 style={{ fontSize: '20px' }}>{counterOn1 && <CountUp start={0} end={idx.count} duration={2} delay={0} />}+</h6>
                                                    </ScrollTrigger>
                                                    <div className="meta-info">
                                                        <div className="author">
                                                            <div className="info1">
                                                                <span>Happy customers</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            ))
                        }
                        {
                            projects.slice(3, 6).map(idx => (
                                <div key={idx.id} className="col-xl-4 col-md-6">
                                    <div className="nft-item">
                                        <div className="card-media">
                                            <Link to="#"><img src={idx.img} alt="exouzia" /></Link>
                                        </div>
                                        <div className="card-title1">
                                            <Link to="#" className="h5">{idx.heading}</Link>
                                        </div>
                                        <div className="meta-info">
                                            <div>
                                                <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'start' }}>{idx.subheading}</p>
                                                <p style={{ paddingTop: '6px', height: '85px' }}>{idx.desc}</p>
                                            </div>
                                        </div>
                                        {/* <div className="card-bottom style-explode">
                                            <div className="price">
                                                <span className="icon-logo-01"></span>
                                                <div className='countplus'>
                                                    <ScrollTrigger onEnter={() => setCounterOn2(true)} onExit={() => setCounterOn2(false)}>
                                                        <h6 style={{ fontSize: '20px' }}>{counterOn2 && <CountUp start={0} end={idx.count} duration={2} delay={0} />}+</h6>
                                                    </ScrollTrigger>
                                                    <div className="meta-info">
                                                        <div className="author">
                                                            <div className="info1">
                                                                <span>Happy customers</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            ))
                        }
                      

                    </div>
                </div>
            </section>
            <div className="page-visions wrapper">
            <Footer />
            </div>

        </div>
    )
}
export default Products