
import React from "react";

import About from "./About";
import AdvisorsBackers from "./AdvisorsBackers";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import Contact from "./Contact";
import HelpCenter from "./HelpCenter";
import Home01 from "./Home01";
import Home02 from "./Home02";
import Home03 from "./Home03";
import Nfts from "./Nfts";
import ParticipantAssets from "./ParticipantAssets";
import Partners from "./Partners";
import RoadMap from "./RoadMap";
import Team from "./Team";
import VisionsMission from "./VisionsMission";
import SoftwareDev from "./softwareDev";
import WebDev from "./WebDev";
import AppDev from "./AppDev";
import Marketing from "./Marketing";
import Branding from "./Branding";
import Seo from "./Seo";
import Products from "./products";
import Camillmormmal from "./Test1" ;
import NextPage from "./testPage";
import Test2 from "./Test2";
// import Slider from "../components/test/test"
import Slider from "./test4"
import Slider2 from "./test5"
import Services from "./services";

import Grades from "./Gradesinternational";
import AiMahal from "./Aimahal";
import GRock from "./Grock";

import Endora from "./endora";
import Aata from "./aata";
import Aalkram from "./aalkram";
import Cocolat from "./cocolat";
import Dispo from "./dispo";
import Genova from "./genova";
import Teabay from "./teabay";
import Tis from "./tis";
import Yazia from "./yazia";
import Masadir from "./masadir";
import Pathraadhi from "./pathradhi";



const routes = [
  { path: '/', component: <Home01 />},
  { path: '/home-v2', component: <Home02 />},
  { path: '/home-v3', component: <Home03 />},

  { path: '/nfts', component: <Nfts />},
  { path: '/blog', component: <Blog />},
  { path: '/blog-details', component: <BlogDetails />},
  { path: '/visions-mission', component: <VisionsMission />},
  { path: '/help-center', component: <HelpCenter />},
  { path: '/participants-assets', component: <ParticipantAssets />},
  { path: '/advisors-backers', component: <AdvisorsBackers />},
  { path: '/partners', component: <Partners />},
  { path: '/about', component: <About />},
  { path: '/road-map', component: <RoadMap />},
  { path: '/team', component: <Team />},
  { path: '/contact', component: <Contact />},

  { path: '/software-development', component: <SoftwareDev />},
  { path: '/Website-development', component: <WebDev />},
  { path: '/Application-development', component: <AppDev />},
  { path: '/digital-marketing', component: <Marketing/>},
  { path: '/company-branding', component: <Branding />},
  { path: '/seo-consulting', component: <Seo />},
  { path: '/products', component: <Products />},

  { path: '/test', component: <Camillmormmal />},
  { path: '/company-branding/works/:paramsId', component: <NextPage />},
  { path: '/test2', component: <Test2 />},
  // { path: '/test3', component: <Slider />},
  { path: '/test4', component: <Slider />},
  { path: '/company-branding/works', component: <Slider2 />},
  { path: '/company-branding/ai-mahal', component: <AiMahal />},
  { path: '/company-branding/grades-international', component: <Grades />},
  { path: '/company-branding/G-rock', component: <GRock />},
  { path: '/Services', component: <Services />},
  { path: '/company-branding/Endora', component: <Endora />},
  { path: '/company-branding/Aata', component: <Aata />},
  { path: '/company-branding/Al-Arkam', component: <Aalkram />},
  { path: '/company-branding/Cocolat', component: <Cocolat />},
  { path: '/company-branding/Dispo', component: <Dispo />},
  { path: '/company-branding/Genova', component: <Genova />},
  { path: '/company-branding/Teabay', component: <Teabay />},
  { path: '/company-branding/Tis', component: <Tis />},
  { path: '/company-branding/Yaziya', component: <Yazia />},
  { path: '/company-branding/Masadir', component: <Masadir />},
  { path: '/company-branding/Pathraadhi', component: <Pathraadhi />},






  
]

export default routes;