import React,{useEffect} from 'react'
import firstBanner from '../assets/images/imgggg/portfolio/logo/grades-webp/first cover.webp'
import LogoConcept from '../assets/images/imgggg/portfolio/logo/grades-webp/logo_concept.webp'
import Logo_Anatomy from '../assets/images/imgggg/portfolio/logo/grades-webp/logo_anatomy.webp'
import Combination_mark_img1 from '../assets/images/imgggg/portfolio/logo/grades-webp/combination_mark.webp'
import Combination_mark_img2 from '../assets/images/imgggg/portfolio/logo/grades-webp/word_mark.webp'
import FlagMark from '../assets/images/imgggg/portfolio/logo/grades-webp/flag_Mark.webp'
import symbolMark from '../assets/images/imgggg/portfolio/logo/grades-webp/symbol_Mark.webp'
import minimal_logo from '../assets/images/imgggg/portfolio/logo/grades-webp/minimal_logo.webp'
import minimal_gradesInt from '../assets/images/imgggg/portfolio/logo/grades-webp/minimal_gradesInt.webp'
import lockups1 from '../assets/images/imgggg/portfolio/logo/grades-webp/lockups1.webp'
import lockups2 from '../assets/images/imgggg/portfolio/logo/grades-webp/lockups2.webp'
import manBanner from '../assets/images/imgggg/portfolio/logo/grades-webp/man_banner.webp'
import final_banner from '../assets/images/imgggg/portfolio/logo/grades-webp/final_banner.webp'
import symbol_options1 from '../assets/images/imgggg/portfolio/logo/grades-webp/symbol_options1.webp'
import symbol_options2 from '../assets/images/imgggg/portfolio/logo/grades-webp/symbol_options2.webp'
import symbol_options3 from '../assets/images/imgggg/portfolio/logo/grades-webp/symbol_options3.webp'
import symbol_options4 from '../assets/images/imgggg/portfolio/logo/grades-webp/symbol_options4.webp'
import { useNavigate } from 'react-router-dom';
import { Row,Col } from 'react-bootstrap'


function Grades() {
    useEffect(() => {
        const header = document.querySelector("header");
        header.style.display = 'none'
    }, [])
    let navigate = useNavigate()
    const backClick =()=>{
        navigate('/company-branding/works/2')
    }
    return (
        <div>
             <Row style={{width:'100vw'}}>
                <Col><p style={{position:'fixed',top:'25px',left:'25px',zIndex:'1000000',color:'#0c0c45',fontSize:'15px',fontWeight:600,cursor:'pointer'}} onClick={()=>backClick()}>BACK</p></Col>
                {/* <Col>
                <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                <p style={{position:'fixed',top:'25px',zIndex:'1000000',color:'#000'}}>Home</p>
                <p style={{position:'fixed',top:'25px',zIndex:'1000000',color:'#000'}}>Contact</p>
                </div>
                </Col> */}
                
            </Row>
            <div className='grades_body'>
                <div className="initialgrades_div">
                    <div className="grades_Col col">
                        <h1>LOGO <br /> GUIDELINES</h1>
                        <div>
                            <span className='thick_dash'></span>
                        </div>
                        <h2>GRADES INTERNATIONAL GLOBAL RESEARCH BOOK AND DEVELOPMENT </h2>
                        <h2 style={{ paddingTop: "0" }}> EDUCATION SOCIETY BRAND BOOK</h2>
                        <p>Created by team from Exouzia.</p>
                        <p>V 1.0 No v'23</p>
                    </div>
                </div>

                <div className="banner_images" style={{ padding: "0 0 30px 0" }}>
                    <div className='inBetween_banner'>
                        <img src={firstBanner} alt="" />
                    </div>
                </div>

                <div className="row" >
                    <div style={{ padding: "0" }}>
                        <span className='thick_dash'></span>
                    </div>
                    <h2>LOGO CONCEPT</h2>
                    <div className="inner_images" xs={12} lg={8}>
                        <img src={LogoConcept} alt="Logo Concept" />
                    </div>
                </div>

                <div className="row" >
                    <div style={{ padding: "0" }}>
                        <span className='thick_dash'></span>
                    </div>
                    <h2>LOGO ANATOMY</h2>
                    <div className="inner_images" xs={12} lg={8}>
                        <img src={Logo_Anatomy} style={{ padding: "50px" }} alt="Logo_Anatomy" />
                    </div>
                </div>

                <div className="combination_mark" >
                    <div>
                        <span className='thick_dash'></span>
                    </div>
                    <div className='logoExclusion' >
                        <h2>LOGO EXCLUSION ZONE</h2>
                        <div className='pof_logoExclusion'>
                            <p >The Exclusion is essentially for your logo to beclearly distinguishable from other graphic elements. Please avoid positioning any elements close than the defined exclusion zone.</p>
                        </div>
                    </div>
                    <h3 className='h3OfcombMark' >COMBINATION MARK</h3>
                    <div className="inner_roww">
                        <div className="col-6">
                            <div className="inner_images" xs={12} lg={8}>
                                <img src={Combination_mark_img1} alt="Logo Concept" />
                            </div>
                        </div>
                        <div className="inner_col col-6">
                            <p >1/2 of the branddark height</p>
                        </div>
                    </div>
                    <div className="wordMark row ">
                        <div className="wordMarkCol col-6">
                            <h3>WORD MARK</h3>
                            <div className="inner_images" xs={12} lg={8}>
                                <img src={Combination_mark_img2} alt="Logo Concept" />
                            </div>
                        </div>
                    </div>
                    <div className="row p-0">
                        <div className="col-6" xs={6} md={6} lg={6}>
                            <h3 className='clsmark'>FLAG MARK</h3>
                            <div className="inner_images" xs={12} lg={8}>
                                <img src={FlagMark} alt="Logo Concept" xs={6} md={6} lg={6} />
                            </div>
                        </div>
                        <div className="col-6 " xs={6} md={6} lg={6}>
                            <h3 className='clsmark'>SYMBOL MARK</h3>
                            <div className="inner_images" xs={12} lg={8}>
                                <img id='symbol_mark' xs={6} md={6} lg={6} src={symbolMark} alt="Logo Concept" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="minimal_logo row" >
                    <div style={{ padding: "0" }}>
                        <span className='thick_dash'></span>
                    </div>
                    <h2>MINIMAL LOGO SIZE</h2>
                    <div className='inner_div'>
                        <div className="inner_images">
                            <img src={minimal_gradesInt} alt="Logo Concept" />
                        </div>
                        <div className="inner_images">
                            <img src={minimal_logo} alt="Logo Concept" />
                        </div>
                    </div>
                </div>

                <div className="Lockups row" >
                    <div style={{ padding: "0" }}>
                        <span className='thick_dash'></span>
                    </div>
                    <h2>LOCKUPS</h2>
                    <p>here are the minimal logo sizes please avoid reducing it further</p>
                    <div className='inside_lockups'>
                        <div className="inner_images" xs={12} lg={8}>
                            <img src={lockups1} alt="Logo Concept" />
                        </div>
                        <div className="inner_images" xs={12} lg={8}>
                            <img src={lockups2} alt="Logo Concept" />
                        </div>
                    </div>
                </div>

                <div className="banner_images">
                    <div className='inBetween_banner'>
                        <img src={manBanner} alt="" />
                    </div>
                </div>

                <div className="symbolOptions row" >
                    <div style={{ padding: "0" }}>
                        <span className='thick_dash'></span>
                    </div>
                    <h2>SYMBOL OPTIONS</h2>
                    <div className='insideSymbol d-flex'>
                        <div className="inside_text" xs={12} lg={8}>
                            <h4>Colored on Dark</h4><br />
                            <p>Colored version of the symbol is preferred.</p>
                        </div>
                        <div className="inside_img" xs={12} lg={8}>
                            <img src={symbol_options1} alt="Logo Concept" />
                        </div>
                    </div>
                    <div className='insideSymbol d-flex'>
                        <div className="inside_text" xs={12} lg={8}>
                            <h4>Colored on Light</h4><br />
                            <p>Colored version of the symbol is preferred.</p>
                        </div>
                        <div className="inside_img" xs={12} lg={8}>
                            <img src={symbol_options2} alt="Logo Concept" />
                        </div>
                    </div>
                    <div className='insideSymbol d-flex'>
                        <div className="inside_text" xs={12} lg={8}>
                            <h4>White on Dark</h4><br />
                            <p>White version of the symbol should be used only for one-color printing.</p>
                        </div>
                        <div className="inside_img" xs={12} lg={8}>
                            <img src={symbol_options3} alt="Logo Concept" />
                        </div>
                    </div>
                    <div className='insideSymbol d-flex'>
                        <div className="inside_text" xs={12} lg={8}>
                            <h4>Dark on White</h4><br />
                            <p>Dark version of the symbol should be used only for one-color printing.</p>
                        </div>
                        <div className="inside_img" xs={12} lg={8}>
                            <img src={symbol_options4} alt="Logo Concept" />
                        </div>
                    </div>
                </div>

                <div className="banner_images" style={{ padding: "0" }}>
                    <div className='inBetween_banner'>
                        <img src={final_banner} alt="" />
                    </div>
                </div>


            </div >
        </div>
    )
}

export default Grades