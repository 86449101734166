import React from 'react'
import one from '../assets/images/imgggg/portfolio/logo/genova/one.webp'
import two from '../assets/images/imgggg/portfolio/logo/genova/two.webp'
import three from '../assets/images/imgggg/portfolio/logo/genova/three.webp'
import four from '../assets/images/imgggg/portfolio/logo/genova/four.webp'
import five from '../assets/images/imgggg/portfolio/logo/genova/five.webp'
import six from '../assets/images/imgggg/portfolio/logo/genova/six.webp'
import seven from '../assets/images/imgggg/portfolio/logo/genova/seven.webp'
import eight from '../assets/images/imgggg/portfolio/logo/genova/eight.webp'
import nine from '../assets/images/imgggg/portfolio/logo/genova/nine.webp'

function Genova() {
  return (
    <div>
      <div className='genova'>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={one} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={two} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={three} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={four} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={five} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={six} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={seven} style={{padding:'1rem 0'}} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={eight} style={{paddingBottom:'1rem 0'}} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={nine} alt="" />
          </div>
        </div>
        <div className="last_dash"></div>
      </div>
    </div>
  )
}

export default Genova