import React from 'react'
import one from '../assets/images/imgggg/portfolio/logo/aata/aataa brochure 0.3_page-0001.webp'
import two from '../assets/images/imgggg/portfolio/logo/aata/aataa brochure 0.3_page-0002.webp'
import three from '../assets/images/imgggg/portfolio/logo/aata/aataa brochure 0.3_page-0003.webp'
import four from '../assets/images/imgggg/portfolio/logo/aata/aataa brochure 0.3_page-0004.webp'
import five from '../assets/images/imgggg/portfolio/logo/aata/aataa brochure 0.3_page-0005.webp'
import six from '../assets/images/imgggg/portfolio/logo/aata/aataa brochure 0.3_page-0006.webp'
import seven from '../assets/images/imgggg/portfolio/logo/aata/aataa brochure 0.3_page-0007.webp'
import eight from '../assets/images/imgggg/portfolio/logo/aata/aataa brochure 0.3_page-0008.webp'

function Aata() {
  return (
    <div>
      <div className='endora'>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={one} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={two} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={three} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={four} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={five} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={six} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={seven} alt="" />
          </div>
        </div>
        <div className="banner_images" style={{ padding: "0" }}>
          <div className='inBetween_banner'>
            <img src={eight} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Aata