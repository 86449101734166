import React,{useEffect} from 'react'
import first from '../assets/images/imgggg/portfolio/logo/grock-webp/1.webp'
import second from '../assets/images/imgggg/portfolio/logo/grock-webp/2.webp'
import third from '../assets/images/imgggg/portfolio/logo/grock-webp/3.webp'
import fourth from '../assets/images/imgggg/portfolio/logo/grock-webp/4.webp'
import fifth from '../assets/images/imgggg/portfolio/logo/grock-webp/5.webp'
import seventh from '../assets/images/imgggg/portfolio/logo/grock-webp/7.webp'
import eight from '../assets/images/imgggg/portfolio/logo/grock-webp/8.webp'
import nine from '../assets/images/imgggg/portfolio/logo/grock-webp/final.webp'
import sym1 from '../assets/images/imgggg/portfolio/logo/grock-webp/symb1.webp'
import sym2 from '../assets/images/imgggg/portfolio/logo/grock-webp/symb2.webp'
import sym3 from '../assets/images/imgggg/portfolio/logo/grock-webp/symb3.webp'
import sym4 from '../assets/images/imgggg/portfolio/logo/grock-webp/symb4.webp'
import { Row,Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';


function GRock() {
    useEffect(() => {
        const header = document.querySelector("header");
        header.style.display = 'none'
    }, [])
    let navigate = useNavigate()
    const backClick =()=>{
        navigate('/company-branding/works/2')
    }

    return (
        <div>
            {/* <div style={{display:'flex',flexDirection:'row'}} >
                <p style={{position:'fixed',top:'50',left:'10px',zIndex:'1000000',color:'#000'}}>Back</p>
                <div>
                <p style={{position:'fixed',top:'10px',left:'10px',zIndex:'1000000',color:'#000'}}>Home</p>
                <p style={{position:'fixed',top:'10px',left:'10px',zIndex:'1000000',color:'#000'}}>Contact</p>
                </div>
                
            </div> */}
            <Row style={{width:'100vw'}}>
                <Col><p style={{position:'fixed',top:'25px',left:'25px',zIndex:'1000000',color:'#0c0c45',fontSize:'15px',fontWeight:600,cursor:'pointer'}} onClick={()=>backClick()}>BACK</p></Col>
                {/* <Col>
                <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                <p style={{position:'fixed',top:'25px',zIndex:'1000000',color:'#000'}}>Home</p>
                <p style={{position:'fixed',top:'25px',zIndex:'1000000',color:'#000'}}>Contact</p>
                </div>
                </Col> */}
                
            </Row>
            <div className="grockBody">
                <div className="firstImg">
                    <img src={first} alt="" />
                </div>
                <div className="firstImg">
                    <img src={second} alt="" />
                </div>
                <div className="firstImg">
                    <img src={third} alt="" />
                </div>
                <div className="firstImg">
                    <img src={fourth} alt="" />
                </div>
                <div className="firstImg">
                    <img src={fifth} alt="" />
                </div>
                <div className="symbOptions">
                    <div className="symb_row row">
                        <p>Symbol Options</p>
                        <div className="symb_Coltxt col-6">
                            <p className='ptag' >Colored on Dark</p >
                            <p>Colored vision of the symbol is preferred</p>
                        </div>
                        <div className="symb_Colimg col-6">
                            <img src={sym1} alt="" />
                        </div>
                        <div className="symb_Coltxt col-6">
                            <p className='ptag' >Colored on Light</p >
                            <p>Colored vision of the symbol is preferred</p>
                        </div>
                        <div className="symb_Colimg col-6">
                            <img src={sym2} alt="" />
                        </div>
                        <div className="symb_Coltxt col-6">
                            <p className='ptag' >White on Dark</p >
                            <p>White version of the symbol should be used only for one-color printing.</p>
                        </div>
                        <div className="symb_Colimg col-6">
                            <img src={sym3} alt="" />
                        </div>
                        <div className="symb_Coltxt col-6">
                            <p className='ptag' >Dark on White</p >
                            <p>Dark version of the symbol should be used only for one-color printing.</p>
                        </div>
                        <div className="symb_Colimg col-6">
                            <img src={sym4} alt="" />
                        </div>
                    </div>
                </div>
                <div className="firstImg">
                    <img src={seventh} alt="" />
                </div>
                <div className="firstImg">
                    <img src={eight} alt="" />
                </div>
                <div className="firstImg">
                    <img src={nine} alt="" />
                </div>
            </div>
        </div>
    )
}

export default GRock