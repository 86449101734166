import React, { useState } from "react";
import PageTitle2 from '../components/pagetitle/PageTitle2'
import Footer from '../components/footer/Footer';
import { Link } from 'react-router-dom';
import Button from '../components/button/Button';
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import gsap from "gsap";
import Transition from "../components/transitions/transition";
import img1 from '../assets/images/imgggg/layouts/swdev 3.webp'

import bizac from '../assets/images/imgggg/works/bizac.webp'
import passdaily from '../assets/images/imgggg/works/passdaily-swdev.webp'
import Ezytrade from '../assets/images/imgggg/works/ezytrade.webp'
import izan from '../assets/images/imgggg/works/izan-digital-mahal.webp'
import smartdawa from '../assets/images/imgggg/works/smart-dawa-1.webp'
import smartdawa2 from '../assets/images/imgggg/works/smart-dawa.webp'
import ecommerce from '../assets/images/imgggg/works/ecommerce.webp'
import erp from '../assets/images/imgggg/works/erp.webp'
import exouzia from '../assets/images/imgggg/works/exouzia.webp'
import gloduate from '../assets/images/imgggg/works/gloduate.webp'
// import izan2 from '../assets/images/imgggg/works/izan.webp'
import laundrymanagment from '../assets/images/imgggg/works/laundry-managment.webp'

import restro from '../assets/images/imgggg/works/restro.webp'


// import img2 from '../assets/images/imgggg/layouts/about-02.webp'
// import img3 from '../assets/images/imgggg/layouts/about-03.webp'
// import img4 from '../assets/images/imgggg/layouts/about-04.webp'
// import img5 from '../assets/images/imgggg/layouts/about-05.webp'

function SoftwareDev(props) {
    const [dataImg] = useState([
        {
            id: 1,
            img: img1,
            class: 'img1'
        }
        // ,
        // {
        //     id: 2,
        //     img: passdaily,
        //     class: 'img2'
        // },
        // {
        //     id: 3,
        //     img: img3,
        //     class: 'img3'
        // },
        // {
        //     id: 4,
        //     img: img4,
        //     class: 'img4'
        // },
        // {
        //     id: 5,
        //     img: img5,
        //     class: 'img5'
        // },
    ])
    const [dataBlock] = useState(
        {
            subheading: 'Our Speciality',
            heading: 'Our Featured Products',

        }
    )
    const [products] = useState([
        {
            id: 1,
            heading: "Izan Digital Mahal & Smart Da'wa",
            subheading: 'The central initiative revolving around the mahals of Kerala',
            link: '',
            img: img1,
            desc: 'Streamline mahal management with intuitive features, scheduling tools, and analytics for enhanced efficiency and organization.',
            count: 235
        },
        {
            id: 2,
            heading: 'Cybrix & Restro',
            subheading: 'Most Trusted ERP Software In Middle East ,Africa & Europe',
            link: '',
            img: img1,
            desc: 'Elevate your business with our ERP solution. Effortlessly integrate POS, access advanced reporting tools, and efficiently manage payroll and HR tasks.',
            count: 100
        },
        {
            id: 3,
            heading: 'AI Mahal',
            subheading: "Foremost enterprise within Kerala's enchanting realm",
            link: '',
            img: img1,
            desc: 'Empowering mahal administrators with smart tools for seamless operations, scheduling, and data-driven decision-making in a user-friendly interface.',
            count: 28
        },
        {
            id: 4,
            heading: 'Rubiz Hotel Management',
            subheading: 'Simplifying Hotel Operations',
            link: '',
            img: img1,
            desc: 'Discover the pinnacle of hotel management software, designed to elevate your hospitality business to new heights effortlessly.',
            count: 350
        },
        {
            id: 5,
            heading: 'Passdaily',
            subheading: 'Modernizing Education: Harnessing Technology for Efficiency',
            link: '',
            img: img1,
            desc: 'Unlock the future of education with our innovative software solution. Seamlessly manage attendance, drive business growth, and enhance communication with our SMS services.',
            count: 150
        },
        {
            id: 6,
            heading: 'VOIP & VPN',
            subheading: 'Streamline Communication and Security with VoIP & VPN',
            link: '',
            img: img1,
            desc: 'Experience seamless communication and enhanced security with our software. Utilize VoIP for clear calls and VPN for privacy. Simplify connectivity with our integrated solution.',
            count: 900
        }
    ])

    const [projects] = useState([
        {
            id: 1,
            heading: "Bizacc Restro",
            subheading: 'Simplifying Hotel Operations',
            link: '',
            img: bizac,
            desc: 'Discover the pinnacle of hotel management software, designed to elevate your hospitality business to new heights effortlessly',
            count: 235
        },
        {
            id: 2,
            heading: 'Passdaily',
            subheading: 'Modernizing Education: Harnessing Technology for Efficiency',
            link: '',
            img: passdaily,
            desc: 'Unlock the future of education with our innovative software solution. Seamlessly manage attendance, drive business growth, and enhance communication with our SMS services.',
            count: 150
        },
        {
            id: 3,
            heading: 'Ezytrade ERP',
            subheading: "Integrated ERP Solutions: Empowering Business Growth",
            link: '',
            img: Ezytrade,
            desc: 'Comprehensive ERP project offering Lite, Basic, Business, and Enterprise versions for streamlined operations at every level .',
            count: 28
        }
    ])

    const [counterOn, setCounterOn] = useState(false);
    const [counterOn1, setCounterOn1] = useState(false);
    const brand = gsap.timeline()

    return (
        <div>
            <Transition timeline={brand} />

            <div className="page-visions wrapper">
                <PageTitle2 title='Software Development' />
                <section className="aboutsp">
                    <div className="container">
                        <div className="row">

                            <div className="col-xl-6 col-md-12">
                                <div className="block-text">
                                    <h6 className="sub-heading"><span>Software Development</span></h6>
                                    <h3 className="heading wow" data-splitting> Building Next-Gen Software Solutions</h3>
                                    <p className="mb-17">we understand that for a software development project to truly succeed,
                                        designing and coding are equally important which are responsible for aesthetic and professional
                                        look and top notch optimal performance.<p><br />all the features  are equally important.</p><br />
                                        The development team at exouzia is proficient in a variety of development tools and operating
                                        environments. <p> <br />Starting from revamping the existing softwares to setting up high
                                            end customised  softwares and portals our entire process is going through rigorous
                                            steps including planning, design, development,testing and maintenance.</p><br /> We are capable of
                                        creating the  software solutions of our customers regardless of the
                                        technologies like software development, industry based customised erp's and software solutions, support and maintanance and performance analysis .</p>
                                </div>

                            </div>
                            <div className="col-xl-6 col-md-12">
                                <div className="about__right">
                                    <div className="images">
                                        {
                                            dataImg.map(idx => (
                                                <img key={idx.id} className={idx.class} src={idx.img} alt="exouzia" />
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                {/* <section className="project sp"> */}
                <section className="nft">
                    {/* <div className="shape right"></div> */}
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="block-text center">
                                    <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                    <h3 className="heading " >{dataBlock.heading}</h3>
                                </div>
                            </div>

                            {/* //.....................previous code............................. classname: project sp */}

                            {/* {
                            products.slice(0,3).map(idx => (
                                <div key={idx.id} className="col-xl-4 col-md-6 " style={{ marginBottom: '10px' }}>
                                    <div className="project-boxx">
                                        <div className="image">
                                            <Link to={idx.link}>
                                                <img src={idx.img} alt="exouzia" />
                                            </Link>
                                        </div>
                                      
                                    </div>
                                    <h6 style={{ fontSize: '25px', display: 'flex', justifyContent: 'start', paddingLeft: '25px' }}>{idx.heading}</h6>
                                    <p style={{ fontSize: '16px', display: 'flex', justifyContent: 'start', paddingLeft: '25px' }}>{idx.subheading}</p>
                                    <p style={{ paddingTop: '18px', paddingLeft: '25px', paddingBottom: '20px' }}>{idx.desc}</p>
                                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                    <h6 style={{ fontSize: '25px'}}>{counterOn && <CountUp start={0} end={idx.count} duration={2} delay={0} />}+</h6>
                                    </ScrollTrigger>
                                </div>
                            ))
                        }
                        {
                            products.slice(3,6).map(idx => (
                                <div key={idx.id} className="col-xl-4 col-md-6 " style={{ marginBottom: '10px' }}>
                                    <div className="project-boxx">
                                        <div className="image">
                                            <Link to={idx.link}>
                                                <img src={idx.img} alt="exouzia" />
                                            </Link>
                                        </div>
                                       
                                    </div>
                                    <h6 style={{ fontSize: '25px', display: 'flex', justifyContent: 'start', paddingLeft: '25px' }}>{idx.heading}</h6>
                                    <p style={{ fontSize: '16px', display: 'flex', justifyContent: 'start', paddingLeft: '25px' }}>{idx.subheading}</p>
                                    <p style={{ paddingTop: '18px', paddingLeft: '25px', paddingBottom: '20px' }}>{idx.desc}</p>
                                    <ScrollTrigger onEnter={() => setCounterOn1(true)} onExit={() => setCounterOn1(false)}>
                                        <h6 style={{ fontSize: '25px'}}>{counterOn1 && <CountUp start={0} end={idx.count} duration={2} delay={0} />}+</h6>
                                    </ScrollTrigger>
                                </div>
                            ))
                        } */}

                            {/* //.....................previous code............................. */}
                            {
                                projects.map(idx => (
                                    <div key={idx.id} className="col-xl-4 col-md-6">
                                        <div className="nft-item">
                                            <div className="card-media">
                                                <Link to="#"><img src={idx.img} alt="exouzia" /></Link>
                                            </div>
                                            <div className="card-title1">
                                                <Link to="#" className="h5">{idx.heading}</Link>
                                            </div>
                                            <div className="meta-info">
                                                <div>
                                                    <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'start' }}>{idx.subheading}</p>
                                                    <p style={{ paddingTop: '6px', height: '85px' }}>{idx.desc}</p>
                                                </div>
                                            </div>
                                            {/* <div className="card-bottom style-explode">
                                                <div className="price">
                                                    <span className="icon-logo-01"></span>
                                                    <div className='countplus'>
                                                        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                                            <h6 style={{ fontSize: '20px' }}>{counterOn && <CountUp start={0} end={idx.count} duration={2} delay={0} />}+</h6>
                                                        </ScrollTrigger>
                                                        <div className="meta-info">
                                                            <div className="author">
                                                                <div className="info1">
                                                                    <span>Happy customers</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div> */}
                                        </div>
                                    </div>
                                ))
                            }
                            {/* {
                            products.slice(3, 6).map(idx => (
                                <div key={idx.id} className="col-xl-4 col-md-6">
                                    <div className="nft-item">
                                        <div className="card-media">
                                            <Link to="#"><img src={idx.img} alt="exouzia" /></Link>
                                        </div>
                                        <div className="card-title1">
                                            <Link to="#" className="h5">{idx.heading}</Link>
                                        </div>
                                        <div className="meta-info">
                                            <div>
                                                <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'start' }}>{idx.subheading}</p>
                                                <p style={{ paddingTop: '6px', height: '85px' }}>{idx.desc}</p>
                                            </div>
                                        </div>
                                        <div className="card-bottom style-explode">
                                            <div className="price">
                                                <span className="icon-logo-01"></span>
                                                <div className='countplus'>
                                                    <ScrollTrigger onEnter={() => setCounterOn1(true)} onExit={() => setCounterOn1(false)}>
                                                        <h6 style={{ fontSize: '20px' }}>{counterOn1 && <CountUp start={0} end={idx.count} duration={2} delay={0} />}+</h6>
                                                    </ScrollTrigger>
                                                    <div className="meta-info">
                                                        <div className="author">
                                                            <div className="info1">
                                                                <span>Happy customers</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        } */}


                        </div>
                    </div>
                </section>
                <Footer />

            </div>
        </div>
    );
}
export default SoftwareDev;